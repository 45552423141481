import { UserRoles } from "modules/authorization/models";
import { TenantNotificationSettings } from "modules/messaging";
import { Tag } from "modules/posts/models";


export enum ShareUrlEvents {
    toggleSpUrl = "toggleSpUrl",
    toggleTeamsUrl = "toggleTeamsUrl",
    toggleCompanyPortalUrl = "toggleCompanyPortalUrl",
    spUrlShareLinkName = "spUrlShareLinkName",
    teamsUrlShareLinkName = "teamsUrlShareLinkName",
    companyPortalUrlShareLinkName = "companyPortalUrlShareLinkName"
}

export enum TeamsEvents {
    spUrl = "spUrl",
    teamsUrl = "teamsUrl",
    companyPortalUrl = "companyPortalUrl",
    spEventsUrl = "spEventsUrl",
    teamsEventsUrl = "teamsEventsUrl",
    companyPortalEventsUrl = "companyPortalEventsUrl"
}

export interface ApplicationState {
    settings: SettingsState;
}

export interface SettingsState {
    fetching: boolean;
    saving: boolean;
    errorMsg: string | null;
    pristine: boolean;
    clientSettings: ClientSettings;
    tenantSettings: TenantSettings;
    pristineTenantSettingsCopy: TenantSettings | null;
    activeReactionTab: ReactionTab;
    activeLanguageLCID: string | null;
    showDisabledItems: boolean;
    currentUser: CurrentUserSettings;
    notificationSettings: TenantNotificationSettings;
    pristineNotificationSettingsCopy: TenantNotificationSettings | null;
    applyBlackoutPeriodsTo: string[];
    tagSettings: TagSettings;
    tenantAttributes: TenantAttribute[];
}

export interface ClientSettings {
    androidAppUrl: string;
    bannerColors: string[];
    bannerVisibility: BannerOptions;
    chatEnabled: boolean;
    commentSystem: CommentSystem;
    defaultLCID: string;
    displayName: string;
    documentsEnabled: boolean;
    // eventHub: IEventHub;
    id: string;
    iosAppUrl: string;
    keys: {
        [value: string]: string;
    };
    mobileEventsEnabled: boolean;
    portalPagesEnabled: boolean;
    reactions: ClientSettingsReaction[];
    reactionSystem: ReactionSystem;
    specialtyPostColors: SpecialtyPostColors;
    surveysEnabled: boolean;
    teams: {
        spUrl: string;
        teamsUrl: string;
        companyPortalUrl: string;
        spEventsUrl: string;
        teamsEventsUrl: string;
        companyPortalEventsUrl: string;
    };
    shareUrlsConfig: {
        toggleSpUrl: boolean;
        toggleTeamsUrl: boolean;
        toggleCompanyPortalUrl: boolean;
        spUrlShareLinkName: string;
        teamsUrlShareLinkName: string;
        companyPortalUrlShareLinkName: string;
    };
    theme: {
        color: string;
        enabled: boolean;
    };
    translatedContent: TranslatedContent;
}

export interface TenantSettings {
    displayName: string;
    reactions: TenantSettingsReaction[];
    reactionsEnabled: boolean;
    isReactingEnabledForNewPostsByDefault: boolean;
    canContributorsToggleReactions: boolean;
    canContributorsToggleComments: boolean;
    tagGroups: TenantSettingsTagGroup[];
    securityGroups: TenantSettingsSecurityGroups[];
    commentsEnabled: boolean;
    isCommentingEnabledForNewPostsByDefault: boolean;
    gifSelectionEnabledForComments: boolean;
    commentMaxLength: number;
    commentModerationEnabled: boolean;
    canManageEveryoneAudience: boolean;
    lobbyEnabled: boolean;
    localAccountsEnabled: boolean;
    localAccountPrefix: string;
    themeEnabled: boolean;
    themeSettings: ThemeSettings | null;
    ADGroupSyncEnabled: boolean;
    groups: Group[];
    defaultLCID: string;
    timeZone: string;
    translatedContent: TranslatedContent;
    mandatoryExpiryTime: number;
    flaggingSettings: {
        minimumNotificationWait: number;
    };
    supportEmail: string;
    tenantId?: string;
    publicAuthAllowed: boolean;
    bannerMessage: string | null;
    bannerVisibility: BannerOptions;
    postBannerColors: string[];
    specialtyPostColors: SpecialtyPostColors | null;
    maxAllowedAudiences: number;
    keys: {
        [value: string]: string;
    };
    manageTopicsAudiencesInAdmin: boolean;
    maxAllowedLanguages: number;
    reportExpiryDays: number;
    surveysEnabled: boolean;
    chatEnabled: boolean;
    docLockerEnabled: boolean;
    documentsEnabled: boolean;
    postSmartContentEnabledByTenant: boolean;
    mobileEventsEnabled: boolean;
    totalUserCountForReporting: number;
    mobilePublishingEnabled: boolean;
    portalPagesEnabled: boolean;
    postNotificationsEnabled: boolean;
    invitedExternalUsersEnabled: boolean;
    resetLocalUserPasswordOnLogin: boolean;
    showFeatures: {
        audiences: boolean;
        documentsEnabled: boolean;
        externalPostSources: boolean;
        everyoneAudienceEnabled: boolean;
        portalPagesEnabled: boolean;
        categoryTagsEnabled: boolean;
        equalToAnySmartAudienceRuleEnabled: boolean;
        asyncReportProcessingEnabled: boolean;
        postSmartContentEnabledForTenant: boolean;
        tinyMceCustomCssEnabled: boolean;
        homeScreenConfigEnabled: boolean;
        digitalScreensEnabled: boolean;
    };
    syncConfig:  {
        mobileSyncConfig: {
            mobileSyncEnabled: boolean;
            graphMobileFieldSource: string;
            oktaMobileFieldSource: string;
        };
        syncLevel: string;
        syncType: string;
        syncAllAdUsers: boolean;
        syncDetails: {
            organizationId: string;
            portalClientId: string;
        };
    }
    customerLicense: CustomerLicenseTypes | null;
    externalPostSources: ExternalPostSource[];
    iosAppUrl: string;
    androidAppUrl: string;
    externalPostPurgeInDays: number;
    externalPostPurgeEnabled: boolean;
    mobileThemeColors: MobileThemeColors | null;
    everyoneAudienceEnforcementEnabled: boolean;
    emailNotificationsEnabled: boolean;
    smsNotificationsEnabled: boolean;
    teams: {
        spUrl: string;
        teamsUrl: string;
        companyPortalUrl: string;
        spEventsUrl: string;
        teamsEventsUrl: string;
        companyPortalEventsUrl: string;
    };
    shareUrlsConfig: {
        toggleSpUrl: boolean;
        toggleTeamsUrl: boolean;
        toggleCompanyPortalUrl: boolean;
        spUrlShareLinkName: string;
        teamsUrlShareLinkName: string;
        companyPortalUrlShareLinkName: string;
    };
}

export interface BannerOptions {
    showRestrictedBanner: boolean;
}

export interface CommentSystem {
    enabled: boolean;
    commentMaxLength: number;
    isCommentingEnabledForNewPostsByDefault: boolean;
    gifSelectionEnabledForComments: boolean;
    moderationEnabled: boolean;
}

export interface ReactionSystem {
    enabled: boolean;
    isReactingEnabledForNewPostsByDefault: boolean;
}

export interface TagSettings {
    tagGroups: ClientSettingsTagGroup[];
    shouldFetch: boolean;
}

export interface TenantAttribute {
    id: string;
    description: string;
    key: string;
    title: string;
}

export interface ThemeSettings {
    color: string | null;
    accent: string | null;
    brandLight: string | null;
}

export class MobileThemeColors {
    accent: string;
    brandLight: string;
}
export class SpecialtyPostColors {
    mandatory: string;
    compliance: string;
    complianceChecked: string;
    breaking: string;
    featured: string;
    restricted: string;
    public: string;
}

export enum SettingsTab {
    General = 2,
    Theme = 3,
    Language = 4,
    Features = 5,
    External = 6,
    Newsletter = 7,
    Messaging = 8,
    ContentSettings = 9,
}

export interface TenantSettingsReaction {
    id: string;
    sortIndex: number;
    sentiment: string;
    name: string;
    enabled: boolean;
}

export enum ReactionTab {
    Enabled = 1,
    Disabled = 2,
}

export interface ClientSettingsReaction {
    id: string;
    name: string;
    order: number;
    sentiment: string;
}

export interface ClientSettingsTagGroup {
    id: string;
    name: string;
    tags: ClientSettingsTag[];
}

export interface ClientSettingsTag {
    disabled: boolean;
    id: string;
    mandatory: boolean;
    name: string;
    restricted: boolean;
    selected: boolean;
}

export interface TenantSettingsTagGroup extends MultiSelectTopicGroup {
    id: string;
    name: string;
    disabled: boolean;
    deleted: boolean;
    tags: TenantSettingsTag[];
    isNew?: boolean;
}

export interface TenantSettingsSecurityGroups {
    id: string;
    name: string;
}

export interface TenantSettingsTag extends MultiSelectTopic {
    id: string;
    name: string;
    mandatory: boolean;
    disabled: boolean;
    deleted: boolean;
    default: boolean;
    isNew?: boolean;
    restrictedTo: string[];
    mandatoryFor: string[];
    defaultFor: string[];
    restricted: boolean;
}

export interface MultiSelectTopic {
    id: string;
    name: string;
    restricted: boolean;
}

export interface MultiSelectTopicGroup {
    id: string;
    name: string;
    tags: MultiSelectTopic[];
}

interface TranslatedContent {
    [lcid: string]: {
        breakingPost: string | null;
        breakingBannerDescription: string | null;
        compliancePost: string | null;
        complianceText: string | null;
        complianceBannerDescription: string | null;
        compliedPost: string | null;
        compliedBannerDescription: string | null;
        featuredPost: string | null;
        featuredBannerDescription: string | null;
        mandatoryPost: string | null;
        mandatoryBannerDescription: string | null;
        publicPost: string | null;
        publicBannerDescription: string | null;
        publicBannerDescriptionShareTitle: string | null;
        restrictedPost: string | null;
        restrictedBannerDescription: string | null;
    };
}

export interface Group {
    id: string;
    name: string;
}

export type CommentNotificationSettings = "accessible" | "engaged" | "subscribed" | "none";

export interface CurrentUserSettings {
    email: string;
    firstName: string;
    lastName: string;
    userId: string;
    roles: UserRoles[];
    canManageSupport: boolean;
    canManageSubmissions: boolean;
    pinnedFolders: string[];
    cardCount: number;
    chatEnabled: boolean;
    commentNotificationSettings: CommentNotificationSettings;
    gradientColor: string;
    hideReadTime: boolean;
    hideSocialStats: boolean;
    hideWhenRead: boolean;
    isSocialUser: boolean;
    isSparrowLocalUser: boolean;
    isWorkAccount: boolean;
    layout: string;
    preferredLCID: string;
    preferredName: string;
    preferredSMSConsent: boolean | null;
    preferredSMSPhone: string;
    sendNotificationsWhenAuthor: boolean;
    subscribedTags: string[];
    localId: string;
    showContentAnalysesCallout: boolean;
    showContentAnalysesDialog: boolean;
    showSmartContentHelpCallouts: boolean;
    showMediaGalleryGridView: boolean;
    providerName: string;
}

export interface CustomerLicenseTypes {
    intranet: IntranetLicenseTypes;
    mobile: MobileLicenseTypes;
    collaboration: CollaborationLicenseTypes;
    crisis: CrisisLicenseTypes;
}

export enum IntranetLicenseTypes {
    SpOnline = 0,
    SpOnPrem = 1,
    None = -1,
}

export enum MobileLicenseTypes {
    Generic = 0,
    Branded = 1,
    None = -1,
}

export enum CollaborationLicenseTypes {
    Teams = 0,
    None = -1,
}

export enum CrisisLicenseTypes {
    Generic = 0,
    None = -1,
}

export interface ExternalPostSource {
    id: string;
    name: string;
    sourceType: ExternalPostSourceTypes;
    topics: Tag[];
    lastChecked: Date;
    enabled: boolean;
    url: string;
    defaultAuthorName: string;
    defaultAuthorEmail: string;
    defaultDraftImage: string;
}

export enum ExternalPostSourceTypes {
    RSS = 1,
}

export interface TranslatableLanguage {
    outputLCID: string;
    breakingPost: string | null;
    breakingBannerDescription: string | null;
    compliancePost: string | null;
    complianceText: string | null;
    complianceBannerDescription: string | null;
    compliedPost: string | null;
    compliedBannerDescription: string | null;
    featuredPost: string | null;
    featuredBannerDescription: string | null;
    mandatoryPost: string | null;
    mandatoryBannerDescription: string | null;
    publicPost: string | null;
    publicBannerDescription: string | null;
    publicBannerDescriptionShareTitle: string | null;
    restrictedPost: string | null;
    restrictedBannerDescription: string | null;
}

export interface IReadabilityOptionModel {
    name: string;
    description: string;
    isDefault: boolean;
    value?: string;
}

export interface IReadabilitySettingsOptionModel {
    readability: IReadabilityOptionModel[];
    tone: IReadabilityOptionModel[];
    personalism: IReadabilityOptionModel[];
    sentiment: IReadabilityOptionModel[];
    gender: IReadabilityOptionModel[];
    length: IReadabilityOptionModel[];
}

export interface IReadabilitySettings {
    id: string;
    readability: string;
    tone: string;
    personalism: string;
    sentiment: string;
    gender: string;
    length: string;
}

export const EMPTY_READABILITY_SETTINGS: IReadabilitySettings = {
    id: "",
    tone: "",
    readability: "",
    personalism: "",
    sentiment: "",
    gender: "",
    length: "",
};

export interface IMobileConfig {
    isWhiteLabel: boolean;
    appName: string;
    dynamicLink: string;
    iosBundleId: string;
    iosAppUrl: string;
    androidAppUrl: string;
    dynamicLinkApiKey: string;
    androidPackageName: string;
    firebaseProjectId: string;
}
