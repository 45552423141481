import React, { useCallback, useEffect, useState, createRef, useRef } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import { withRouter, RouteComponentProps } from "react-router";
import { IntlShape, injectIntl } from "react-intl";
import { push } from 'react-router-redux';
import moment from 'moment';

import { insightsApi, postsApi, reportsApi } from 'api/instances';
import {
    ActivityInsightsDetails,
    defaultPostAnalysisScores,
    defaultPostOverview,
    DetailedPostInsights,
    ImageScale,
    POST_TYPES,
    PostAnalysisScores,
    PostInsightBreakdowns,
    PostOverview,
    PostAverages
} from 'modules/posts/models';
import confirm from 'utils/notyPopups';
import * as actions from 'modules/posts/actionCreator';

import BackToButton from 'modules/common/components/buttons/backToButton';
import ContentOverviewSideBar from './components/contentOverviewSideBar';
import DownloadButtonv2 from 'modules/common/components/buttons/downloadButtonv2';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import LoadingOverlay from 'modules/common/components/loadingOverlay';
import ReadingComprehensionAnalysis from './components/readingComprehensionAnalysis';
import Loading from 'modules/common/components/loading';
import BreakdownChart from './components/breakdownChart';
import PostInsightsCharts from './components/postInsightsCharts';
import ContentOverviewCard from './components/contentOverviewCard';
import ActivityInsights from './components/activityInsights';

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GetAppIcon from '@mui/icons-material/GetApp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import useMediaQuery from '@mui/material/useMediaQuery';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import PublicIcon from '@mui/icons-material/Public';
import {
    ButtonGroup,
    ClickAwayListener,
    List,
    ListItem,
    ListItemText,
    Popover,
    Tooltip,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import './postInsights.sass'
import './styles/basic-insights.sass'
import MoreOptionsButton from 'modules/common/components/buttons/moreOptionsButton';
import HoverText from 'modules/documents/components/action-buttons/hoverText';
import Preview, { PreviewType } from 'modules/common/components/authoring/dialogs/preview';
import { openPostInPortal } from 'utils/redirectUtils';
import PostContentView from '../post-view/postContentView';
import { ReportTypes } from 'modules/reports/models';
import SnackbarWrapper from 'modules/common/components/snackbars/snackbarWrapper';
import cookie from 'utils/cookie';
import AnalyticsDrawer from './components/analyticsDrawer';
import { QUERY_PARAM_KEYS } from 'modules/common/hooks/useQueryParams';
import ExportDigitalScreensBtn from 'modules/digitalScreens/components/exportDigitalScreensBtn';
import { ExportDigitalScreensModal } from 'modules/digitalScreens/components/exportDigitalScreensModal';

const SIDE_BAR_MENU_WIDTH = 225
const SIDE_BAR_CONTENT_WIDTH = 315
const SCORE_ICON_SRC: string = "/images/icons/change_history.svg";
const SYNC_ARROWS_SRC: string = "/images/icons/sync_arrows.svg";
// const INSIGHTS_START_DATE = new Date('June 1, 2021')

// TODO: add react-intl where neceessary here post fix
// TODO: break this up into re-usable components
const PostInsights: React.FunctionComponent<PropsWithRedux> = (props) => {
    const setTenantAveragesSetting = (toSet: boolean) => {
        setIsUsingTenantAverages(toSet);
        cookie.setTenantAveragesSetting(toSet);
    }

    const matchesXLarge = useMediaQuery('(min-width:1850px)')
    const matchesLarge = useMediaQuery('(max-width:1610px)')
    const matchesMed = useMediaQuery('(max-width:1024px)')

    const keyInsightsRef = useRef<HTMLDivElement>(null)
    const postInsightsRef = useRef<HTMLDivElement>(null)
    const channelBreakdownRef = useRef<HTMLDivElement>(null)
    const languageBreakdownRef = useRef<HTMLDivElement>(null)
    const readingCompRef = useRef<HTMLDivElement>(null)
    const complianceBreakdownRef = useRef<HTMLDivElement>(null)
    const activityRef = useRef<HTMLDivElement>(null)

    const [isLoadingPost, setIsLoadingPost] = useState(false)
    const [postDetails, setPostDetails] = useState<PostOverview>({
        ...defaultPostOverview,
        id: props.match.params.postId
    })
    const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false)
    const [postAnalysis, setPostAnalysis] = useState<PostAnalysisScores>(defaultPostAnalysisScores)
    const [postAnalysisReportReady, setPostAnalysisReportReady] = useState(true)

    const [isLoadingDetailedInsights, setIsLoadingDetailedInsights] = useState(false)
    const [detailedInsights, setDetailedInsights] = useState<DetailedPostInsights>()

    const [isLoadingInsightBreakdowns, setIsLoadingInsightBreakdowns] = useState(false)
    const [insightBreakdowns, setInsightBreakdowns] = useState<PostInsightBreakdowns>({ channelBreakdown: [], languageBreakdown: [], reportIsReady: true })

    const [isLoadingActivityInsights, setIsLoadingActivityInsights] = useState(false)
    const [activityInsights, setActivityInsights] = useState<ActivityInsightsDetails>()

    const [isLoadingTenantAverages, setIsLoadingTenantAverages] = useState(false);
    const [tenantPostAverages, setTenantPostAverages] = useState<PostAverages>()

    const [isLoadingGlobalAverages, setIsLoadingGlobalAverages] = useState(false);
    const [globalPostAverages, setGlobalPostAverages] = useState<PostAverages>()

    const [isUsingTenantAverages, setIsUsingTenantAverages] = useState(cookie.getTenantAveragesSetting()); //use this to toggle between global and tenant scores

    const [windowWidth, setWindowWidth] = useState(window.innerWidth - SIDE_BAR_MENU_WIDTH - SIDE_BAR_CONTENT_WIDTH)
    const [navWidth, setNavWidth] = useState(0)
    const [navPage, setNavPage] = useState(0)

    const [showPreview, setShowPreview] = useState(false)
    const [showActionCommandList, setShowActionCommandList] = useState(false)

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [showPopover, setShowPopover] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showExportDialog, setShowExportDialog] = useState(false);

    const [allPageNum, setAllPageNum] = useState("1");
    const [pubPageNum, setPubPageNum] = useState("1");
    const [schPageNum, setSchPageNum] = useState("1");
    const [dftPageNum, setDftPageNum] = useState("1");
    const [tabNum, setTabNum] = useState("0");

    const popoverRef = useRef(null);

    // hide open in portal if post has not been published yet
    const hideOpenInPortalPreviewAction = !postDetails.datePublished || moment(postDetails.datePublished).isAfter(moment());

    const postsForExport = [{...postDetails, publishedTime: { date: new Date(postDetails.datePublished) }}];

    const [navButtons, setNavButtons] = useState([{ title: 'key insights', ref: createRef<any>(), scrollTo: keyInsightsRef },
    { title: 'post insights', ref: createRef<any>(), scrollTo: postInsightsRef },
    { title: 'channel breakdown', ref: createRef<any>(), scrollTo: channelBreakdownRef },
    { title: 'reading & comprehension', ref: createRef<any>(), scrollTo: readingCompRef },
    { title: 'language breakdown', ref: createRef<any>(), scrollTo: languageBreakdownRef },
    { title: 'compliance', ref: createRef<any>(), scrollTo: complianceBreakdownRef },
    { title: 'activity', ref: createRef<any>(), scrollTo: activityRef }])

    const [showPostEditedWarning, setShowPostEditedWaring] = useState(false)

    const determinePostState = (expired: boolean, publishTime: Date): string => {
        let now = moment()
        if (expired) {
            return "Expired"
        }
        else if (moment(publishTime) <= now) {
            return "Published"
        }
        else if (moment(publishTime) > now) {
            return "Scheduled"
        }
        return ""
    }

    const determinePostBannersSet = (featured: boolean, breaking: boolean): string => {
        if (featured && breaking) {
            return "Featured & Breaking"
        }
        else if (featured) {
            return "Featured"
        }
        else if (breaking) {
            return "Breaking"
        }
        return ""
    }

    const determineScrollWidths = useCallback(() => {
        let totalWidth = 0
        navButtons.forEach(item => {
            totalWidth += (item.ref.current?.offsetWidth + 5 || 0)
        })
        setNavWidth(totalWidth)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDetailedPostInsights = useCallback((fetchCompliance: boolean) => {
        setIsLoadingDetailedInsights(true)
        insightsApi.GetDetailedPostInsights(props.match.params.postId, fetchCompliance)
            .then(result => {
                setDetailedInsights({
                    ...result,
                    firstPublishedDate: new Date(result.firstPublishedDate.toString())
                })
                setIsLoadingDetailedInsights(false)
            })
            .catch(error => setIsLoadingDetailedInsights(false))
    }, [props.match.params.postId]);

    const fetchPostActivityInsights = useCallback((fetchComments: boolean, fetchReactions: boolean) => {
        setIsLoadingActivityInsights(true)
        insightsApi.GetPostActivityInsights(props.match.params.postId, fetchComments, fetchReactions)
            .then(result => {
                setActivityInsights(result)
                setIsLoadingActivityInsights(false)
            })
            .catch(error => setIsLoadingActivityInsights(false))
    }, [props.match.params.postId]);

    const fetchPostDetails = useCallback(() => {
        setIsLoadingPost(true)

        postsApi.GetPostOverview(props.match.params.postId, ImageScale.Modal)
            .then(post => {
                setPostDetails({
                    ...post,
                    state: determinePostState(post.isExpired, new Date(post.datePublished)),
                    bannersSet: determinePostBannersSet(post.isFeatured, post.isBreaking)
                })
                setIsLoadingPost(false)
                fetchDetailedPostInsights(post.postType === 'compliance')
                fetchPostActivityInsights(post.commentingEnabled, post.reactingEnabled)

                let updatedNavButtons = navButtons;
                if (post.postType !== 'compliance') {
                    updatedNavButtons = updatedNavButtons.filter(item => item.title !== 'compliance')
                    setNavButtons(updatedNavButtons)
                }
                else {
                    updatedNavButtons = updatedNavButtons.filter(item => item.title !== 'activity')
                    setNavButtons(updatedNavButtons)
                }

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchDetailedPostInsights, fetchPostActivityInsights, props.match.params.postId]);

    const scrollToView = (ref: React.RefObject<HTMLDivElement> | null) => {
        if (ref) {
            ref.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
    }

    const fetchPostAnalysis = useCallback(() => {
        setIsLoadingAnalysis(true)
        postsApi.GetFirstPublishedPostAnalysisScores(props.match.params.postId)
            .then(result => {
                setPostAnalysis(result.postAnalysisScores)
                setPostAnalysisReportReady(result.reportIsReady)
            })
            .catch()
            .finally(() => setIsLoadingAnalysis(false));
    }, [props.match.params.postId]);

    const fetchInsightBreakdowns = useCallback(() => {
        setIsLoadingInsightBreakdowns(true)
        insightsApi.GetPosttInsightBreakdowns(props.match.params.postId)
            .then(result => {
                setInsightBreakdowns(result)
            })
            .catch()
            .finally(() => setIsLoadingInsightBreakdowns(false));
    }, [props.match.params.postId]);

    const fetchTenantPostAverages = useCallback(() => {
        setIsLoadingTenantAverages(true);
        postsApi.GetTenantPostAverages()
            .then(result => {
                setTenantPostAverages(result);
            })
            .catch()
            .finally(() => setIsLoadingTenantAverages(false));
    }, []);

    const fetchGlobalPostAverages = useCallback(() => {
        setIsLoadingGlobalAverages(true);
        postsApi.GetGlobalPostAverages()
            .then(result => {
                setGlobalPostAverages(result);
            })
            .catch()
            .finally(() => setIsLoadingGlobalAverages(false));
    }, []);

    useEffect(() => {
        determineScrollWidths();
        fetchPostDetails();
        fetchPostAnalysis();
        fetchInsightBreakdowns();
        fetchTenantPostAverages();
        fetchGlobalPostAverages();

        //Pull the context from the url of where the user was on the post management screen.
        const queryParams = new URLSearchParams(window.location.search);
        const pageContext = (queryParams.get(QUERY_PARAM_KEYS.PAGE_CONTEXT) ?? "");
        const contextNums = pageContext.split(",");

        if (contextNums.length === 5) {
            setAllPageNum(contextNums[0]);
            setPubPageNum(contextNums[1]);
            setSchPageNum(contextNums[2]);
            setDftPageNum(contextNums[3]);
            setTabNum(contextNums[4]);
        }

    }, [determineScrollWidths, fetchPostDetails, fetchPostAnalysis, fetchInsightBreakdowns, fetchTenantPostAverages, fetchGlobalPostAverages]);

    useEffect(() => {
        if (windowWidth > navWidth) {
            setNavPage(0)
        }
    }, [windowWidth, navWidth])

    useEffect(() => {
        setShowPostEditedWaring(postDetails.changedSinceFirstPublish)
    }, [postDetails.changedSinceFirstPublish])

    const deletePost = async () => {
        if (await confirm.show({ text: 'Are you sure you want to delete this post?' })) {
            props.deletePosts([{ draftId: props.match.params.postId, status: 'published' }])
                .then(() => {
                    props.redirectTo(`/${props.tenant}/admin/posts/`)
                })
        }
    }

    const handleWindowResize = () => {
        let value = window.innerWidth - SIDE_BAR_MENU_WIDTH - SIDE_BAR_CONTENT_WIDTH
        if (matchesMed) {
            value = window.innerWidth - SIDE_BAR_MENU_WIDTH - 10
        }
        setWindowWidth(value)
    }

    const calculatePercent = (firstVal: number, secondVal: number): string => {
        let result = (firstVal / secondVal) * 100;
        if (result === Infinity || isNaN(result)) {
            return `0%`
        }
        if (result < 0.5) {
            result = Math.ceil(result)
        }
        result = Math.round(result)

        return `${result}%`
    }

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: "12px"
        }
    }))(Tooltip);

    const onEdit = async () => {
        const confirmationMessage: string = `Editing a compliance post and publishing its changes will reset its Compliance Report, clearing the list of users who have complied to it.`;

        if (postDetails.usersComplied === false) {
            props.redirectTo(`/${props.tenant}/admin/posts/edit/${postDetails.draftId}`);
        }
        else if (await confirm.show({
            title: `Edit Compliance Post`,
            text: (
                <div>
                    <div>{confirmationMessage}</div>
                    <br />
                    <div>Would you like to proceed?</div>
                </div>
            ),
            yesColor: "#3b78ab",
            yesText: "Edit",
            noText: "Cancel"
        })) {
            props.redirectTo(`/${props.tenant}/admin/posts/edit/${postDetails.draftId}`);
        }
    }

    const actionButtons = (): JSX.Element => {
        return (
            <React.Fragment>
                <Button color="primary" size="small" disableElevation startIcon={<VisibilityIcon />} onClick={() => setShowPreview(true)}>
                    {props.intl.formatMessage({ id: 'posts.preview', defaultMessage: "Preview" })}
                </Button>
                {
                    postDetails.editable &&
                    <Button color="primary" size="small" disableElevation startIcon={<EditIcon />} onClick={onEdit}>
                        {props.intl.formatMessage({ id: 'common.edit', defaultMessage: "Edit" })}
                    </Button>
                }
                <ExportDigitalScreensBtn 
                    selectedPosts={postsForExport}
                    onClick={() => setShowExportDialog(true)}
                />
                <Button color="primary" size="small" startIcon={<DeleteIcon />} disableElevation onClick={deletePost}>
                    {props.intl.formatMessage({ id: 'common.delete', defaultMessage: "Delete" })}
                </Button>
            </React.Fragment>
        )
    }

    const loadingComplete = !isLoadingDetailedInsights && !isLoadingInsightBreakdowns && !isLoadingAnalysis && !isLoadingActivityInsights && !isLoadingTenantAverages && !isLoadingGlobalAverages;
    const allReportsNotReady = !detailedInsights?.reportIsReady && !insightBreakdowns?.reportIsReady && !postAnalysisReportReady;
    const detailedInsightsReady = !allReportsNotReady && detailedInsights?.reportIsReady
    const breakdownsReady = !allReportsNotReady && insightBreakdowns?.reportIsReady
    // const dataIsOlder = new Date(detailedInsights?.firstPublishedDate!) < INSIGHTS_START_DATE;

    /**
     * Get the desktop preview component
     */
    const getDesktopPreview = (): JSX.Element => (
        <div className="post-preview">
            {postDetails &&
                <PostContentView
                    tinyMceCustomCssEnabled={props.tenantSettings.showFeatures.tinyMceCustomCssEnabled}
                    reactions={props.tenantSettings.reactions.filter((t) => t.enabled).map((t) => ({ ...t, order: t.sortIndex }))}
                    reactionsEnabledOnTenant={props.tenantSettings.reactionsEnabled}
                    commentsEnabledOnTenant={props.tenantSettings.commentsEnabled}
                    post={postDetails}
                    lcidMappings={props.lcidMappings}
                    defaultLcid={props.defaultLCID}
                />}
        </div>
    );

    const renderPostInsights = (): JSX.Element => {
        return <div className='insights-wrapper' ref={postInsightsRef} style={{ height: !matchesLarge ? '380px' : '' }}>
            <div className='heading'>
                {props.intl.formatMessage({ id: 'posts.postInsights', defaultMessage: "Post Insights" })}
            </div>
            {
                isLoadingDetailedInsights ?
                    <Loading />
                    : detailedInsightsReady ?
                        <React.Fragment>
                            <PostInsightsCharts 
                                readTypeData={[{ value: detailedInsights?.allTimeSkims!, interval: "All Time", label: "Skims" },
                                    { value: detailedInsights?.allTimeReads!, interval: "All Time", label: "Reads" },
                                    { value: detailedInsights?.allTimeCompleteReads!, interval: "All Time", label: "Complete Reads" },
                                    { value: detailedInsights?.allTimeOutlierReads!, interval: "All Time", label: "Outlier Reads" },
                                    { value: detailedInsights?.first7DaysSkims!, interval: "First 7 Days", label: "Skims" },
                                    { value: detailedInsights?.first7DaysReads!, interval: "First 7 Days", label: "Reads" },
                                    { value: detailedInsights?.first7DaysCompleteReads!, interval: "First 7 Days", label: "Complete Reads" },
                                    { value: detailedInsights?.first7DaysOutlierReads!, interval: "First 7 Days", label: "Outlier Reads" },
                                    { value: detailedInsights?.first30DaysSkims!, interval: "First 30 Days", label: "Skims" },
                                    { value: detailedInsights?.first30DaysReads!, interval: "First 30 Days", label: "Reads" },
                                    { value: detailedInsights?.first30DaysCompleteReads!, interval: "First 30 Days", label: "Complete Reads" },
                                    { value: detailedInsights?.first30DaysOutlierReads!, interval: "First 30 Days", label: "Outlier Reads" }]}
                                engagementData={[{ value: detailedInsights?.allTimeUpvotes!, interval: "All Time", label: "Comment likes" },
                                    { value: detailedInsights?.allTimeComments!, interval: "All Time", label: "Comments" },
                                    { value: detailedInsights?.allTimeReactions!, interval: "All Time", label: "Reactions" },
                                    { value: detailedInsights?.first7DaysUpvotes!, interval: "First 7 Days", label: "Comment likes" },
                                    { value: detailedInsights?.first7DaysComments!, interval: "First 7 Days", label: "Comments" },
                                    { value: detailedInsights?.first7DaysReactions!, interval: "First 7 Days", label: "Reactions" },
                                    { value: detailedInsights?.first30DaysUpvotes!, interval: "First 30 Days", label: "Comment likes" },
                                    { value: detailedInsights?.first30DaysComments!, interval: "First 30 Days", label: "Comments" },
                                    { value: detailedInsights?.first30DaysReactions!, interval: "First 30 Days", label: "Reactions" }]}
                                totalViews={detailedInsights?.allTimeTotalViews!}
                                totalEngagement={detailedInsights?.totalEngagement!}
                                firstPublishDate={detailedInsights?.firstPublishedDate!}
                                compliancePost={postDetails.postType === 'compliance'}
                                disclaimer={(!postDetails.commentingEnabled || !postDetails.reactingEnabled) ? `*${postDetails.commentingEnabled ? '' : 'Comments'}${!postDetails.commentingEnabled && !postDetails.reactingEnabled ? ' and ' : ''}${postDetails.reactingEnabled ? '' : 'Reactions'} were disabled` : null}
                            />
                            <div>
                                <LightTooltip placement='right-end' title='A post view is measured as a Skim if the time spent viewing the post is less than 30% of the posts estimated read time.
                                    It is measured as a Read if the viewer spends more than 30%, but less than 70% of the estimated read time. Every view which exceeds 70% is a Complete Read. Lastly, it is measured as an
                                    Outlier if the time spent in viewing is more than 400%.'>
                                    <span className='info'>What are skims, reads and outliers?</span>
                                </LightTooltip>
                            </div>
                        </React.Fragment>
                        : <div className='empty-widget'>
                            No found insights at the moment. Please try again later.
                        </div>

            }
        </div>
    }

    const sendReadReportRequest = async () => {
        try {
            await reportsApi.SendUserReadReportRequest(ReportTypes.UserReadReport, props.match.params.postId);
            setSuccessMessage("Your report is being generated and will be emailed to you when it's ready.");
        }
        catch (e) {
            setErrorMessage("Something went wrong. Please try again.");
        }
    }

    const renderChannelBreakdown = (): JSX.Element => {
        return <div className='insights-wrapper' ref={channelBreakdownRef} style={{ height: !matchesLarge ? '380px' : '', flexGrow: !matchesLarge ? 'unset' : 1 }}>
            <div className='heading'>
                {props.intl.formatMessage({ id: 'posts.channelBreakdown', defaultMessage: "Channel Breakdown" })}
                {
                    isLoadingInsightBreakdowns ?
                        <Loading />
                        : breakdownsReady ?
                            <BreakdownChart chartData={insightBreakdowns?.channelBreakdown!.map(b => {
                                return { id: b.platformName, value: b.viewsOnPlatform, label: `${b.platformName}: ${b.viewsOnPlatform}` }
                            })}
                                totalViews={detailedInsights?.allTimeTotalViews!}
                                innerRadius={0.8}
                            />
                            : <div className='empty-widget'>
                                No found insights at the moment. Please try again later.
                            </div>
                }
            </div>
        </div>
    }

    const renderLanguageBreakdown = (): JSX.Element => {
        return <div className='insights-wrapper' ref={languageBreakdownRef} style={{ flexGrow: !matchesLarge ? 'unset' : 1 }}>
            <div className='heading'>
                {props.intl.formatMessage({ id: 'posts.languageBreakdown', defaultMessage: "Language Breakdown" })}
                {
                    isLoadingInsightBreakdowns ?
                        <Loading />
                        : breakdownsReady ?
                            <BreakdownChart chartData={insightBreakdowns?.languageBreakdown!.map(b => {
                                return { id: b.language, value: b.viewsOnLCID, label: `${b.language}: ${b.viewsOnLCID}` }
                            })}
                                totalViews={detailedInsights?.allTimeTotalViews!}
                                innerRadius={0.7}
                            />
                            : <div className='empty-widget'>
                                No found insights at the moment. Please try again later.
                            </div>
                }
            </div>
        </div>
    }

    const renderComplianceBreakdown = (): JSX.Element => {
        const chartData = detailedInsights?.usersNotViewedNotComplied === null ?
            [{ id: 'ViewsNotComplied', value: detailedInsights?.usersViewedNotComplied!, label: `Viewed but not complied: ${detailedInsights?.usersViewedNotComplied!}` },
            { id: 'Complied', value: detailedInsights?.totalCompliedUsers!, label: `Complied: ${detailedInsights?.totalCompliedUsers!}` }]
            : [{ id: 'NotViewed', value: detailedInsights?.usersNotViewedNotComplied!, label: `Not Viewed: ${detailedInsights?.usersNotViewedNotComplied!}` },
            { id: 'ViewsNotComplied', value: detailedInsights?.usersViewedNotComplied!, label: `Viewed but not complied: ${detailedInsights?.usersViewedNotComplied!}` },
            { id: 'Complied', value: detailedInsights?.totalCompliedUsers!, label: `Complied: ${detailedInsights?.totalCompliedUsers!}` }]

        return <div className='insights-wrapper' ref={complianceBreakdownRef}>
            <div className='header'>
                <div className='heading'>
                    {props.intl.formatMessage({ id: 'posts.compliance', defaultMessage: "Compliance" })}
                </div>
                <DownloadButtonv2 styles={{ color: "primary", size: "small", disableElevation: true, startIcon: <GetAppIcon /> }}
                    downloadName={postDetails.translatedContent[props.defaultLCID]['title'] + '-compliance-report'}
                    text={props.intl.formatMessage({ id: 'insights.downloadCSVReport', defaultMessage: "Download CSV Report" })}
                    onClick={() => insightsApi.GetComplianceReportCSV(props.match.params.postId)}
                />
                {
                    detailedInsights?.usersNotViewedNotComplied != null ?
                        <LightTooltip placement='right-end' title='Total Subscribers are the count of Subscribers at Publish Time of the Post. Not Viewed is the total number of subscribers that have not opened the post yet;
                            Viewed but Not Complied are subscribers that have viewed the post, but have not complied to it; and Complied are subscribers who have acknowledged and complied to the post.'>
                            <HelpOutlineIcon />
                        </LightTooltip>
                        : null
                }
            </div>
            {
                isLoadingDetailedInsights ?
                    <Loading />
                    : detailedInsightsReady ?
                        <BreakdownChart chartData={chartData}
                            totalSubscribers={detailedInsights?.totalSubscriberCountAtPublishTime!}
                            totalUniqueViews={detailedInsights?.totalCompliedUsers! + detailedInsights?.usersViewedNotComplied!}
                            innerRadius={0.7}
                        />
                        : <div className='empty-widget'>
                            No found insights at the moment. Please try again later.
                        </div>
            }
        </div>
    }

    const keyInsights = [{ value: detailedInsights?.allTimeUniqueOpens, title: 'Users viewed this post' },
    { value: calculatePercent(detailedInsights?.allTimeUniqueOpens!, detailedInsights?.totalSubscriberCountAtPublishTime!), title: 'Viewers vs. Total Subscribers', description: `${detailedInsights?.allTimeUniqueOpens!} of ${detailedInsights?.totalSubscriberCountAtPublishTime!}` },
    { value: calculatePercent(detailedInsights?.allTimeUniqueOpens!, detailedInsights?.reachableUsersAtPublishTime!), title: 'Viewers vs. Total Reachable', description: `${detailedInsights?.allTimeUniqueOpens!} of ${detailedInsights?.reachableUsersAtPublishTime!}` },
    { value: calculatePercent(activityInsights?.totalUniqueInteractions!, detailedInsights?.allTimeUniqueOpens!), title: 'Of viewers interacted', description: `${activityInsights?.totalUniqueInteractions!} of ${detailedInsights?.allTimeUniqueOpens!}` },
    { value: detailedInsights?.allTimeTotalViews, title: 'Total views' },
    { value: detailedInsights?.allTimeCompleteReads, title: 'Complete Reads' },
    { value: detailedInsights?.allTimeComments, title: `Comments${!postDetails.commentingEnabled ? '*' : ''}` },
    { value: detailedInsights?.allTimeReactions, title: `Reactions${!postDetails.reactingEnabled ? '*' : ''}` }]

    if (isLoadingPost)
        return <LoadingOverlay show={isLoadingPost} absolute />

    let navButtonsToScroll = (navPage * (navWidth - windowWidth + 30))
    const backToTarget = `admin/posts?allPageNum=${allPageNum}&pubPageNum=${pubPageNum}&schPageNum=${schPageNum}&dftPageNum=${dftPageNum}&tabNum=${tabNum}`;

    return (
        <BasePage fullWidth>
            <Breadcrumb
                items={[
                    { title: postDetails.translatedContent[props.defaultLCID]['title'], link: "~/admin/posts" },
                    { title: props.intl.formatMessage({ id: "insights.insightsAndReports", defaultMessage: "Insights & Reports" }) }
                ]}
            />
            <div className='post-insights'>
                <div className='action-buttons'>
                    <BackToButton locationTitle='Manage Posts' to={backToTarget} />
                    <div className='right-buttons'>
                        {
                            matchesMed ?
                                <div className='list-button'>

                                    <ButtonGroup color="primary" size='small'>
                                        <Button color="primary" size="small" disableElevation onClick={() => setShowPreview(true)}>
                                            {props.intl.formatMessage({ id: 'posts.preview', defaultMessage: "Preview" })}
                                        </Button>
                                        <Button color="primary" size="small" onClick={() => setShowActionCommandList(true)}>
                                            <ArrowDropDownIcon />
                                        </Button>
                                    </ButtonGroup>
                                    {
                                        showActionCommandList &&
                                        <ClickAwayListener onClickAway={() => setShowActionCommandList(false)}>
                                            <List className='action-button-list'>
                                                {
                                                    postDetails.editable &&
                                                    <ListItem button onClick={onEdit}>
                                                        <ListItemText primary="Edit" />
                                                    </ListItem>
                                                }
                                                <ListItem button onClick={deletePost}>
                                                    <ListItemText primary="Delete" />
                                                </ListItem>
                                            </List>
                                        </ClickAwayListener>
                                    }
                                </div>
                                : actionButtons()
                        }
                        <div className='list-button'>

                            <div ref={popoverRef}
                                onMouseEnter={() => setShowPopover(true)}
                                onMouseLeave={() => setShowPopover(false)}
                            >
                                <Popover
                                    open={showPopover && (!(postDetails.postType === 'compliance' || detailedInsights?.reportIsReady))}
                                    anchorEl={popoverRef.current}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    PaperProps={{
                                        style: {
                                            maxWidth: 250
                                        }
                                    }}
                                    style={{ pointerEvents: "none" }}
                                    onClose={() => setShowPopover(false)}
                                    classes={{
                                        paper: "help-icon-description"
                                    }}
                                >
                                    <span>
                                        No insights are found at the moment. Please try downloading the report again later.
                                    </span>
                                </Popover>
                                <MoreOptionsButton text="Download Reports" variant="contained" size="small" disabled={(!(postDetails.postType === 'compliance' || detailedInsights?.reportIsReady))}>
                                    <List className='action-button-list'>
                                        {
                                            postDetails.postType === 'compliance' &&
                                            <ListItem>
                                                <DownloadButtonv2 styles={{ color: "primary", size: "small", disableElevation: true }}
                                                    downloadName={postDetails.translatedContent[props.defaultLCID]['title'] + '-compliance-report'}
                                                    text={props.intl.formatMessage({ id: 'insights.downloadComplianceReport', defaultMessage: "Compliance Report" })}
                                                    onClick={() => insightsApi.GetComplianceReportCSV(props.match.params.postId)}
                                                />
                                            </ListItem>
                                        }
                                        {
                                            detailedInsights?.reportIsReady &&
                                            <ListItem>
                                                <Button
                                                    size='small'
                                                    color='primary'
                                                    disableElevation={true}
                                                    onClick={sendReadReportRequest}
                                                >
                                                    {props.intl.formatMessage({ id: 'insights.downloadUserReadsReport', defaultMessage: "User Read Report" })}
                                                </Button>
                                            </ListItem>
                                        }
                                    </List>
                                </MoreOptionsButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    {
                        !matchesMed &&
                        <div className='post-side'>
                            <ContentOverviewSideBar postDetails={postDetails} defaultLCID={props.defaultLCID} />
                        </div>
                    }
                    <div className='insights-side' style={{ width: matchesMed ? '100%' : '' }}>
                        {
                            matchesMed &&
                            <ContentOverviewCard postDetails={postDetails} defaultLCID={props.defaultLCID} />
                        }
                        <div className='nav-header'>
                            <div className='nav-buttons'>
                                <div className='nav-buttons-wrapper' style={{ transform: `translate(${navButtonsToScroll}px`, transition: 'transform 1s' }}>
                                    {navButtons.map(item => {
                                        return <Button key={item.title} size="small" color="primary" variant='outlined' classes={{ root: 'round-button' }} ref={item.ref} onClick={() => scrollToView(item.scrollTo)}>{item.title}</Button>
                                    })}
                                </div>
                            </div>
                            {
                                navPage === -1 &&
                                <Button size="small" color="primary" aria-label='align' classes={{ root: 'arrow-button left' }} onClick={() => setNavPage(0)}>
                                    <ArrowBackIosIcon />
                                </Button>
                            }
                            {windowWidth < navWidth && navPage !== -1 &&
                                <Button size="small" color="primary" classes={{ root: 'arrow-button right' }} onClick={() => setNavPage(-1)}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            }
                        </div>
                        {
                            showPostEditedWarning && loadingComplete && !allReportsNotReady &&
                            <div className='warning'>
                                <WarningIcon style={{ color: '#e6911a' }} />
                                <span>We found modifications made to your post. Take note that insights are gathered from post details and settings set from the original published version only.</span>
                            </div>
                        }
                        {
                            allReportsNotReady && loadingComplete ?
                                <div className='insights-wrapper'>
                                    <div className='empty-widget'>
                                        No found insights at the moment. Please try again later.
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div className='insights-wrapper' ref={keyInsightsRef} >
                                        <div className='header'>
                                            <div className='heading'>
                                                {props.intl.formatMessage({ id: 'posts.keyInsights', defaultMessage: "Key Insights" })}
                                            </div>
                                            <LightTooltip placement='left-start' title='Key Insights is the summary of all the data provided below. These give an overview of the performance of this post.'>
                                                <HelpOutlineIcon />
                                            </LightTooltip>
                                        </div>
                                        {
                                            isLoadingDetailedInsights || detailedInsights === undefined ?
                                                <Loading />
                                                : detailedInsightsReady ?
                                                    <React.Fragment>
                                                        <div style={{ display: !matchesXLarge && !matchesLarge ? 'flex' : 'block' }}>
                                                            <div className='stats-row'>
                                                                {
                                                                    keyInsights.map((item, index) => {
                                                                        if (!matchesXLarge) {
                                                                            if (index >= 4) return null
                                                                        }
                                                                        return <div key={index} className='number-wrapper'>
                                                                            <div className={`number ${!matchesXLarge && !matchesLarge ? 'left' : ''}`}>
                                                                                {
                                                                                    (detailedInsights.totalSubscriberCountAtPublishTime === null && index === 1) ||
                                                                                        (detailedInsights.reachableUsersAtPublishTime === null && (index === 2 || index === 3)) ?
                                                                                        "N/A"
                                                                                        : item.value
                                                                                }
                                                                            </div>
                                                                            <span className='title'>
                                                                                {item.title}
                                                                            </span>
                                                                            {
                                                                                item.description &&
                                                                                <span className='description'>
                                                                                    {
                                                                                        (detailedInsights.totalSubscriberCountAtPublishTime === null && index === 1) ||
                                                                                            (detailedInsights.reachableUsersAtPublishTime === null && (index === 2 || index === 3)) ?
                                                                                            "Not Available"
                                                                                            : item.description
                                                                                    }
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            {!matchesXLarge &&
                                                                <div className={`stats-row ${!matchesLarge ? 'wrap' : ''}`}>
                                                                    <div style={{ display: "flex", flexGrow: 1, width: matchesLarge ? '50%' : '' }}>
                                                                        {
                                                                            keyInsights.map((item, index) => {
                                                                                if (index < 4 || index > 5) return null
                                                                                return <div key={index} className='number-wrapper'>
                                                                                    <div className='number'>
                                                                                        {item.value}
                                                                                    </div>
                                                                                    <span className='title'>
                                                                                        {item.title}
                                                                                    </span>
                                                                                    {
                                                                                        item.description &&
                                                                                        <span className='description'>
                                                                                            {item.description}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            })

                                                                        }
                                                                    </div>
                                                                    <div style={{ display: "flex", flexGrow: 1, width: matchesLarge ? '50%' : '' }}>
                                                                        {
                                                                            keyInsights.map((item, index) => {
                                                                                if (index < 6) return null
                                                                                return <div key={index} className='number-wrapper' style={{ marginTop: !matchesLarge ? 0 : '' }}>
                                                                                    <div className='number'>
                                                                                        {item.value}
                                                                                    </div>
                                                                                    <span className='title'>
                                                                                        {item.title}
                                                                                    </span>
                                                                                    {
                                                                                        item.description &&
                                                                                        <span className='description'>
                                                                                            {item.description}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            (!postDetails.commentingEnabled || !postDetails.reactingEnabled) &&
                                                            <div className='graphs-disclaimer' style={{ textAlign: 'right', marginRight: '12px' }}>{`*${postDetails.commentingEnabled ? '' : 'Comments'}${!postDetails.commentingEnabled && !postDetails.reactingEnabled ? ' and ' : ''}${postDetails.reactingEnabled ? '' : 'Reactions'} were disabled`}</div>
                                                        }
                                                    </React.Fragment>
                                                    : <div className='empty-widget'>
                                                        No found insights at the moment. Please try again later.
                                                    </div>
                                        }
                                    </div>
                                    {
                                        matchesLarge &&
                                        <React.Fragment>
                                            {renderPostInsights()}
                                            <div className='insight-row'>
                                                {renderChannelBreakdown()}
                                                {renderLanguageBreakdown()}
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        matchesLarge && postDetails.postType === 'compliance' && renderComplianceBreakdown()
                                    }
                                    <div className={!matchesLarge ? 'insight-row' : ''}>
                                        <div className='insight-column' style={{ flexGrow: 2 }}>
                                            {
                                                !matchesLarge && renderPostInsights()
                                            }
                                            <div className='insights-wrapper' ref={readingCompRef}>
                                                <div className='header'>
                                                    <div className='heading'>
                                                        {props.intl.formatMessage({ id: 'posts.readingAnalysis', defaultMessage: "Reading & Comprehension Analysis*" })}
                                                    </div>
                                                    <div style={{ fontSize: "10px", fontWeight: "normal" }}>
                                                        {isUsingTenantAverages ?
                                                            <>
                                                                <WhereToVoteIcon htmlColor="#adb7c1" style={{ width: 13, color: "#adb7c1", marginRight: 5, position: "relative", top: "8px" }} />
                                                                <HoverText labelClassName="legend-text" inline label="Internal avg.">
                                                                    Average scores taken from your organization's most engaging posts.
                                                                </HoverText>
                                                            </>
                                                            :
                                                            <>
                                                                <PublicIcon htmlColor="#599FA0" style={{ width: 13, color: "#599FA0", marginRight: 5, position: "relative", top: "8px" }} />
                                                                <HoverText labelClassName="legend-text-global" inline label="Global avg.">
                                                                    <span>Global average scores are taken from most engaging posts in Sparrow.</span>
                                                                </HoverText>
                                                            </>}
                                                        <img alt="Your score" src={SCORE_ICON_SRC} width={16} style={{ marginRight: 5, marginLeft: 15, }} />
                                                        <span className="legend-text" style={{ color: "#3b78ab" }}>Current scores</span>
                                                    </div>

                                                    {!!tenantPostAverages && !!globalPostAverages &&
                                                        <AnalyticsDrawer
                                                            isOpen={showDrawer}
                                                            closeDrawer={() => setShowDrawer(false)}
                                                            postAnalysis={postAnalysis}
                                                            tenantAverages={tenantPostAverages}
                                                            globalAverages={globalPostAverages}
                                                            isUsingTenantAverages={isUsingTenantAverages}
                                                            setIsUsingTenantAverages={setIsUsingTenantAverages}
                                                        />
                                                    }
                                                </div>
                                                {
                                                    (isLoadingAnalysis || isLoadingTenantAverages || isLoadingGlobalAverages) ?
                                                        <Loading />
                                                        : (postAnalysisReportReady && !!tenantPostAverages && !!globalPostAverages) ?
                                                            <>
                                                                <ReadingComprehensionAnalysis
                                                                    postAnalysis={postAnalysis}
                                                                    globalAverages={globalPostAverages}
                                                                    tenantAverages={tenantPostAverages}
                                                                    timeSpentReading={detailedInsights?.averageTimeSpentReadingUI!}
                                                                    isUsingTenantAverages={isUsingTenantAverages}
                                                                />
                                                                <div>
                                                                    <Button color='primary' onClick={() => setTenantAveragesSetting(!isUsingTenantAverages)} startIcon={
                                                                        <img src={SYNC_ARROWS_SRC} style={{ width: "26px" }} alt={"Swap average locale icon"}></img>
                                                                    }>
                                                                        {isUsingTenantAverages ? "Use Global Scores" : "Use Internal Scores"}
                                                                    </Button>
                                                                    <Button color='primary' onClick={() => setShowDrawer(true)} style={{ float: "right" }}>
                                                                        See All Analysis
                                                                    </Button>
                                                                </div>
                                                            </>
                                                            : <div className='empty-widget'>
                                                                No found insights at the moment. Please try again later.
                                                            </div>
                                                }

                                            </div>
                                            {
                                                postDetails.postType !== 'compliance' &&
                                                <div className='insights-wrapper' ref={activityRef}>
                                                    <div className='header'>
                                                        <div className='heading' >
                                                            {props.intl.formatMessage({ id: 'activity.activity', defaultMessage: "Activity" })}
                                                        </div>
                                                        <Button color="primary" size="small" disableElevation startIcon={<ExitToAppIcon />} onClick={() => props.redirectTo(`/${props.tenant}/admin/activity`)}>
                                                            Go to flagged comments
                                                        </Button>
                                                    </div>
                                                    {
                                                        isLoadingActivityInsights ?
                                                            <Loading />
                                                            : <ActivityInsights
                                                                totalComments={detailedInsights?.allTimeComments!}
                                                                totalReactions={detailedInsights?.allTimeReactions!}
                                                                activityInsights={activityInsights!}
                                                                showComments={postDetails.commentingEnabled}
                                                                showReactions={postDetails.reactingEnabled}
                                                                totalUniqueOpens={detailedInsights?.allTimeUniqueOpens!}
                                                            />
                                                    }
                                                </div>
                                            }
                                        </div>

                                        <div className='insight-column' style={{ flexGrow: 1, minWidth: '350px' }}>
                                            {
                                                !matchesLarge ?
                                                    <React.Fragment>
                                                        {renderChannelBreakdown()}
                                                        {renderLanguageBreakdown()}
                                                    </React.Fragment>
                                                    : null
                                            }
                                            {
                                                !matchesLarge && postDetails.postType === 'compliance' && renderComplianceBreakdown()
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                        }
                    </div>

                </div>
                {showPreview &&
                    <Preview
                        editAction={postDetails.postType === POST_TYPES.COMPLIANCE ? undefined : onEdit}
                        editLabel={"EDIT POST"}
                        openAction={hideOpenInPortalPreviewAction ? undefined : () => openPostInPortal(props.tenant, postDetails.id)}
                        openLabel={hideOpenInPortalPreviewAction ? undefined : "OPEN POST IN PORTAL"}
                        onClose={() => setShowPreview(false)}
                        open={showPreview}
                        previews={[{ type: PreviewType.Desktop, label: "DESKTOP", component: getDesktopPreview() }]}
                    />
                }
                <WindowResizeListener onChange={handleWindowResize} />
                <SnackbarWrapper open={!!successMessage} style={{ top: "60px" }} autoHideDuration={8000} onClose={() => setSuccessMessage("")} severity="success" message={successMessage} />
                <SnackbarWrapper open={!!errorMessage} style={{ top: "60px" }} autoHideDuration={8000} onClose={() => setErrorMessage("")} severity="error" message={errorMessage} />
            </div>
            <ExportDigitalScreensModal
                selectedPosts={postsForExport}
                onCloseExportDialog={() => setShowExportDialog(false)}
                showExportDialog={showExportDialog}
            />
        </BasePage>
    );
}

interface ComponentProps { intl: IntlShape; }

interface RouteParams {
    postId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        tenant: state.tenant.id,
        asyncReportsEnabled: state.settings.tenantSettings.showFeatures.asyncReportProcessingEnabled,
        defaultLCID: state.settings.tenantSettings.defaultLCID,
        tenantSettings: state.settings.tenantSettings,
        lcidMappings: state.resources.lcidMappings,
    }),
    {
        redirectTo: push,
        deletePosts: actions.deleteDraftPosts,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(withRouter(PostInsights)));
