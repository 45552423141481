import React from "react";
import { Grid, Typography } from "@mui/material";

const GRID_ROW_WIDTH = 12;
const DEFAULT_LABEL_WIDTH = 3;
const DEFAULT_FIELD_WIDTH = GRID_ROW_WIDTH - DEFAULT_LABEL_WIDTH;

interface IDialogFieldProps {
    fieldName: string | JSX.Element;
    fieldInput?: JSX.Element;
    labelWidth?: number;
    fieldWidth?: number;
    fieldStyle?: React.CSSProperties;
    mandatory?: boolean;
    fieldLabelGridClassName?: string;
}

// TODO: consolidate with MandatoryField?
const DialogField: React.FunctionComponent<IDialogFieldProps> = (props) => {
    const fieldWidth = props.labelWidth && props.labelWidth < GRID_ROW_WIDTH ? GRID_ROW_WIDTH - props.labelWidth : DEFAULT_FIELD_WIDTH;

    return (
        <React.Fragment>
            <Grid 
                item 
                xs={props.labelWidth ?? DEFAULT_LABEL_WIDTH}
                className={props.fieldLabelGridClassName}
            >
                {
                    typeof props.fieldName === "string" 
                        ? <Typography display={"inline"}>
                            {props.fieldName}
                        </Typography>
                        : props.fieldName
                }
                {
                    props.mandatory &&
                    <Typography display={"inline"} className="red-text">
                        *
                    </Typography>
                }
            </Grid>
            <Grid item xs={props.fieldWidth ?? fieldWidth} style={props.fieldStyle}>
                {props.fieldInput}
            </Grid>
        </React.Fragment>
    );
};

export default DialogField;