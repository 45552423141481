import * as Actions from './actions';
import { POST_STATUS_TYPES, PostsState } from './models';
import { Reducer } from './index';

export const actionHandler: Reducer<PostsState> = {

    [Actions.SET_NEW_POST_LIST]: (state: PostsState, action: Actions.setNewPostList) => {
        return {
            ...state,
            publishedPostList: {
                ...state.publishedPostList,
                page: action.page
            }
        };
    },

    [Actions.FETCH_DRAFT_POST_LIST]: (state: PostsState, action: Actions.FetchDraftPostList) => {
        return {
            ...state,
            draftPostList: {
                ...state.draftPostList,
                isFetching: true
            }
        };
    },

    [Actions.FETCH_DRAFT_POST_LIST_COMPLETE]: (state: PostsState, action: Actions.FetchDraftPostListComplete) => {
        return {
            ...state,
            draftPostList: {
                ...state.draftPostList,
                ...action.page,
                isFetching: false
            },
            isInitialLoad: false
        };
    },

    [Actions.FETCH_EXPIRED_POST_LIST]: (state: PostsState, action: Actions.FetchExpiredPostList) => {
        return {
            ...state,
            expiredPostList: {
                ...state.expiredPostList,
                isFetching: true
            }
        };
    },

    [Actions.FETCH_EXPIRED_POST_LIST_COMPLETE]: (state: PostsState, action: Actions.FetchExpiredPostListComplete) => {
        return {
            ...state,
            expiredPostList: {
                ...state.expiredPostList,
                ...action.page,
                isFetching: false
            },
            isInitialLoad: false
        };
    },

    [Actions.FETCH_PUBLISHED_POST_LIST]: (state: PostsState, action: Actions.FetchPublishedPostList) => {
        return {
            ...state,
            publishedPostList: {
                ...state.publishedPostList,
                isFetching: true
            }
        };
    },

    [Actions.FETCH_PUBLISHED_POST_LIST_COMPLETE]: (state: PostsState, action: Actions.FetchPublishedPostListComplete) => {
        return {
            ...state,
            publishedPostList: {
                ...state.publishedPostList,
                ...action.page,
                isFetching: false
            },
            isInitialLoad: false
        };
    },

    [Actions.FETCH_SCHEDULED_POST_LIST]: (state: PostsState, action: Actions.FetchScheduledPostList) => {
        return {
            ...state,
            scheduledPostList: {
                ...state.scheduledPostList,
                isFetching: true
            }
        };
    },

    [Actions.FETCH_SCHEDULED_POST_LIST_COMPLETE]: (state: PostsState, action: Actions.FetchScheduledPostListComplete) => {
        return {
            ...state,
            scheduledPostList: {
                ...state.scheduledPostList,
                ...action.page,
                isFetching: false
            }
        };
    },

    [Actions.FETCH_SUBMISSION_POST_LIST]: (state: PostsState, action: Actions.FetchSubmissionPostList) => {
        return {
            ...state,
            submissionPostList: {
                ...state.submissionPostList,
                isFetching: true
            }
        };
    },

    [Actions.FETCH_SUBMISSION_POST_LIST_COMPLETE]: (state: PostsState, action: Actions.FetchSubmissionPostListComplete) => {
        return {
            ...state,
            submissionPostList: {
                ...state.submissionPostList,
                ...action.page,
                isFetching: false
            }
        };
    },

    [Actions.FETCH_ALL_POST_LIST]: (state: PostsState, action: Actions.FetchAllPostList) => {
        return {
            ...state,
            allPostList: {
                ...state.allPostList,
                isFetching: true
            }
        };
    },

    [Actions.FETCH_ALL_POST_LIST_COMPLETE]: (state: PostsState, action: Actions.FetchAllPostListComplete) => {
        return {
            ...state,
            allPostList: {
                ...state.allPostList,
                ...action.page,
                isFetching: false
            }
        };
    },

    [Actions.DRAFT_POST_DELETE]: (state: PostsState, action: Actions.DraftPostDelete) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                deleting: true
            }
        };
    },

    [Actions.DRAFT_POST_DELETE_COMPLETE]: (state: PostsState, action: Actions.DraftPostDeleteComplete) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                deleting: false
            },
            errorMessage: action.succeeded ? "" : "Unable to delete post",
            shouldFetch: action.succeeded
        };
    },

    [Actions.DRAFT_POST_DELETE_COMPLETE_BATCH]: (state: PostsState, action: Actions.DraftPostDeleteCompleteBatch) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                deleting: false
            },
            errorMessage: action.succeeded ? "" : "Unable to delete post(s)",
            shouldFetch: true
        };
    },

    [Actions.PUBLISH_POST]: (state: PostsState, action: Actions.PublishPost) => {
        return { ...state, publishing: true, publishValidationErrors: { errors: {}, title: null, id: null } };
    },

    [Actions.PUBLISH_POST_COMPLETE]: (state: PostsState, action: Actions.PublishPostComplete) => {
        let publishValidationErrors = (!action.succeeded && action.validationResult) ? {
            id: action.validationResult.postId,
            title: action.validationResult.postTitle,
            errors: { ...action.validationResult.errors },
        } : {id: null, title: null, errors: {}};

        return {
            ...state,
            publishValidationErrors,
            publishing: false,
            errorMessage: action.succeeded ? "" : "Unable to publish post"
        } as PostsState;
    },

    [Actions.PUBLISH_SUCCESSFUL]: (state: PostsState, action: Actions.PublishSuccessful) => {
        let newDraftPostList = [ ...state.draftPostList.posts ];
        let index = newDraftPostList.findIndex(p => p.id === action.id);
        if (index >= 0) {
            newDraftPostList[index].status = POST_STATUS_TYPES.PUBLISHING;
        }
        return {
            ...state,
            draftPostList: {
                ...state.draftPostList,
                posts: newDraftPostList
            },
            publishConfirmation: {
                shouldShowDialog: true,
                containsVideoContent: action.containsVideoContent || false,
                expectedPublishTime: action.expectedPublishTime || '',
            },
            shouldFetch: true
        } as PostsState
    },

    [Actions.HIDE_PUBLISH_SUCCESSFUL_DIALOG]: (state: PostsState, action: Actions.HidePublishSuccessfulDialog) => {
        return {
            ...state,
            publishConfirmation: {
                shouldShowDialog: false,
                containsVideoContent: false,
                expectedPublishTime: '',
            }
        };
    },

    [Actions.SUBMIT_POST]: (state: PostsState, action: Actions.HidePublishSuccessfulDialog) => {
        return { ...state, submitting: true, };
    },

    [Actions.SUBMIT_POST_COMPLETE]: (state: PostsState, action: Actions.HidePublishSuccessfulDialog) => {
        return { 
            ...state, 
            submitting: false,
            shouldFetch: true,
        };
    },

    [Actions.UNPUBLISH_POST]: (state: PostsState, action: Actions.UnpublishPost) => {
        return {
            ...state,
            unpublishing: true
        };
    },

    [Actions.UNPUBLISH_POST_COMPLETE]: (state: PostsState, action: Actions.UnpublishPostComplete) => {
        return {
            ...state,
            unpublishing: false,
            errorMessage: action.succeeded ? "" : "Unable to unpublish post",
            shouldFetch: action.succeeded
        };
    },

    [Actions.CLEAR_POST_AUTHORING_ERROR_MESSAGE]: (state: PostsState, action: Actions.ClearPostAuthoringErrorMessage) => {
        return {
            ...state,
            errorMessage: ""
        };
    },

    [Actions.CLEAR_SHOULD_FETCH]: (state: PostsState, action: Actions.ClearShouldFetch) => {
        return {
            ...state,
            shouldFetch: false
        };
    }

}
