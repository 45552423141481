import React from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import "../styles/languageSelect.sass";

interface ILanguageSelectProps {
    currLcid: string;
    onChangeLcid: (lcid: string) => void;
}

const LanguageSelect: React.FC<ILanguageSelectProps> = ({
    currLcid,
    onChangeLcid
}) => {
    const lcidMapping = useSelector((state: GlobalApplicationState) => state.resources.lcidMappings);
    const allTenantTCs = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings.translatedContent);
    const defaultTenantLcid = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings.defaultLCID);

    const allTenantLcids = Object.keys(allTenantTCs);
    
    return <Select
        variant="outlined"
        value={currLcid}
        onChange={e => onChangeLcid(e.target.value)}
        size="small"
        sx={{width: "100%"}}
        renderValue={val => lcidMapping[val].language}
    >
        {
            Array.from(allTenantLcids).map(lcid =>
                lcidMapping[lcid] &&
                <MenuItem value={lcid} key={lcid}>
                    {
                        defaultTenantLcid == lcid
                            ? <div className="default-language-label-container">
                                {lcidMapping[lcid].language}
                                <span className="default-language-indicator-label"><i>Default</i></span>
                            </div>
                            : lcidMapping[lcid].language
                    }
                </MenuItem>
            )
        }
    </Select>;
};

export default LanguageSelect;
