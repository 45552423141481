import { FlaggedComment } from "modules/activity";
import { CroppableImage, IIndicatorDetail, TranslatableContent, ValidationChecks } from "modules/common/components/authoring/models";
import { AttachedFile, CustomFileData, MediaFile, Image, SortGroupName } from "modules/gallery/models";
import { SupportItem, SupportTab } from "modules/support/models";
import { SortStyle, PostState } from "utils/managementUtils";

export interface ApplicationState {
    posts: PostsState
}

export interface ImageLibraryState {
    fetching: boolean
    deleting: boolean
    uploading: boolean
    images: Image[]
    shouldFetch: boolean
    lastFetched: number | null
    continuationToken: string | null
    imagesGalleryMap: Record<string, CustomFileData>
    mediaFiles: MediaFile[]
    chonkyFileData: CustomFileData[]
    totalCount: number;
    totalPages: number;
    currDir?: MediaFile;
    folderChain: CustomFileData[];
    sortGroup: SortGroupName;
    toggleShowFoldersFirst: boolean;
}

export interface DocumentDirectoryState {
    fetching: boolean
    deleting: boolean
    uploading: boolean
    files: Document[]
    shouldFetch: boolean
    lastFetched: number | null
    continuationToken: string | null
}
export interface PostsState {
    draftPostList: PostListingPage;
    expiredPostList: PostListingPage;
    publishedPostList: PostListingPage;
    scheduledPostList: PostListingPage;
    submissionPostList: PostListingPage;
    allPostList: PostListingPage;
    editor: EditorState,
    creatingNewPost: boolean,
    imageLibrary: ImageLibraryState,
    documentDirectory: DocumentDirectoryState,
    errorMessage: string;
    isInitialLoad: boolean;
    postsFeed: PostsFeedState,
    postView: PostViewState,
    publishing: boolean,
    submitting: boolean,
    publishValidationErrors: DraftPostPublishingValidationErrors,
    publishConfirmation: PublishConfirmation,
    unpublishing: boolean;
    tips: TipsState
    activeSupportTab: SupportTab;
    shouldFetch: boolean;
}

export interface EditorState {
    post: Partial<Post>,
    supportItem: SupportItem,
    fetching: boolean,
    saving: boolean,
    deleting: boolean,
    image: {
        id: string | null,
        url: string | null,
        transforms: { points: number[], zoom?: number } | null
    },
    changedSinceSaved: boolean,
    isValid: boolean;
    validationChecks: ValidationChecks;
}

export interface PublishConfirmation {
    shouldShowDialog: boolean,
    expectedPublishTime: string,
    containsVideoContent: boolean
}

export type CommentingTypes = "standard" | "anonymous";
export type CommentNotificationSetting = "accessible" | "engaged" | "subscribed" | "none";

export interface Post {
    id: string;
    image: CroppableImage;
    video: { id: string, url: string } | null;
    tags: { id: string, name: string }[];
    author: string;
    authorEmail?: string;
    commentingEnabled: boolean;
    commentingType: CommentingTypes;
    createdTime: Date | null;
    publishTime: Date | null;
    expiryTime: Date | null;
    breakingTime: Date | null;
    featuredTime: Date | null;
    isPreviouslyPublished?: boolean;
    notifications: Notifications;
    reactingEnabled: boolean;
    revisions: Revision[];
    translatedContent: TranslatedContent;
    postType: string | null;
    attachedContent: AttachedFile[];
    fileAttachments: AttachedFile[];
    isSubmission: boolean;
}

export interface PostsFeedState {
    postsFeed: PostFeedItem[];
    canLoadMore: boolean;
    errorMessage: string;
    fetching: boolean;
    filters: Partial<PostFeedFilters>;
    filtersApplied: boolean;
    shouldFetch: boolean;
    showFilters: boolean;
}

export interface PostFeedItem {
    attachedContent?: AttachedFile[];
    author: {
        avatar: {
            color: string;
        };
        email: string;
        name: string;
    };
    bannerColor: string;
    body: string;
    commentingEnabled: boolean;
    commentingType: CommentingTypes;
    comments: Comment[];
    defaultLCID: string;
    excerpt: string;
    fileAttachments?: AttachedFile[];
    id: string;
    image: string;
    imageUrl: string;
    isBreaking: boolean;
    isExpired: boolean;
    isFeatured: boolean;
    isQuickPost: boolean;
    isRestricted: boolean;
    lastReadTime?: string;
    mandatory: boolean;
    postType: string;
    preferredLCID: string;
    publishTime: string;
    reactingEnabled: boolean;
    readingTime?: string;
    shareUrl: string;
    sourceId?: string;
    stats: PostViewStats;
    status: string;
    tags: { id: string, name: string }[];
    title: string;
    translatedContent: TranslatedContent;
}

export interface PostOverview {
    id: string;
    draftId: string;
    author: {
        avatar: {
            color: string;
        };
        email: string;
        name: string;
    };
    lastPublishedByName: string;
    editable: boolean;
    image?: CroppableImage;
    imageUrl: string;
    tags: { id: string, name: string, restricted: boolean }[];
    datePublished: string;
    isBreaking: boolean;
    isFeatured: boolean;
    isExpired: boolean;
    state?: string;
    updatedTime: string;
    postType: string;
    translatedContent: TranslatedContent;
    cropPoints: number[] | null;
    attachedContent: AttachedFile[];
    fileAttachments: AttachedFile[];
    bannersSet?: string;
    bannerColor: string;
    commentingEnabled: boolean;
    reactingEnabled: boolean;
    preferredLCID: string;
    changedSinceFirstPublish: boolean;
    status: string;
    revisions?: Revision[];
    usersComplied: boolean;
    previouslyPublishedPostId: string;
    isSubmission: boolean;
    notifications: Notifications;
}

export const defaultPostOverview: PostOverview = {
    id: '',
    draftId: '',
    author: {
        name: '',
        email: '',
        avatar: { color: '' }
    },
    lastPublishedByName: '',
    editable: false,
    imageUrl: '',
    tags: [],
    datePublished: '',
    state: '',
    updatedTime: '',
    isFeatured: false,
    isExpired: false,
    isBreaking: false,
    postType: '',
    translatedContent: { "en-us": { title: "", body: "", description: "" } },
    cropPoints: null,
    attachedContent: [],
    fileAttachments: [],
    bannersSet: '',
    bannerColor: '',
    commentingEnabled: true,
    reactingEnabled: true,
    preferredLCID: 'en-us',
    changedSinceFirstPublish: false,
    status: "draft",
    previouslyPublishedPostId: '',
    usersComplied: false,
    isSubmission: false,
    notifications: {
        emailOnPublish: "none",
        mobileOnPublish: "subscribers",
        smsOnPublish: "none",
        teamsOnPublish: "subscribers",
        reminders: [
            { channel: "none", delay: 4, range: "fallback" }
        ]
    },
}

export interface PostViewState {
    errorMessage: string;
    savingComment: boolean;
    shouldFetch: boolean;
    savingReaction: boolean;
}

export interface PostView {
    attachedContent: AttachedFile[];
    author: {
        avatar: {
            color: string;
        };
        email: string;
        name: string;
    };
    bannerColor: string;
    body: string;
    commentingEnabled: boolean;
    commentingType: CommentingTypes;
    comments: Comment[];
    complianceText: string;
    createdTime: string;
    defaultLCID: string;
    excerpt: string;
    fileAttachments: AttachedFile[];
    id: string;
    image: string;
    imageUrl: string;
    isBreaking: boolean;
    isEditable: boolean;
    isExpired: boolean;
    isFeatured: boolean;
    isPostNotificationsActive: boolean;
    isQuickPost: boolean;
    isRestricted: boolean;
    lastReadTime: string;
    mandatory: boolean;
    postType: string;
    preferredLCID: string;
    publishTime: string;
    reactingEnabled: boolean;
    readingTime: string;
    shareUrl: string;
    sourceId: string;
    stats: PostViewStats;
    status: string;
    tags: { id: string, name: string }[];
    title: string;
    translatedContent: TranslatedContent;
    userCommentNotificationSettings: CommentNotificationSetting;
    video: string;
}

export interface PostViewStats {
    commentCount: number;
    complianceTime: string;
    lastOpenTime: string;
    lastReadTime: string;
    postId: string;
    reactions: Reaction[];
    viewCount: number;
}

export enum PostListingPageId {
    EMPTY = "",
    DRAFTS = "drafts",
    EXPIRED = "expired",
    PUBLISHED = "published",
    SCHEDULED = "scheduled",
    SUBMISSIONS = "submissions",
    ALL = "all"
}

export interface PostListingPage {
    id: PostListingPageId;
    currentPage: number;
    posts: PostListItem[];
    totalPages: number;
    totalPosts: number;
    isFetching: boolean;
}

export type Layouts = "card" | "column" | "window";

export interface Reaction {
    id: string;
    selected: boolean;
    count: number;
}

export interface ReactionStat {
    postId: string;
    id: string;
    sentiment: string;
    name: string;
    count: number;
    selected?: boolean;
}

export interface Comment {
    author: {
        avatar: {
            color: string;
        };
        email: string;
        id: string;
        name: string;
    };
    body: string;
    formattedBody: string;
    commentType: string;
    createdTime: string;
    flaggedByCurrentUser?: boolean;
    id: string;
    isDestarrable?: boolean;
    isEditable?: boolean;
    starCount?: number;
    starredByCurrentUser?: boolean;
    replyCount?: number;
    parentId?: string;
    repliesPreview: Comment[];
    updatedTime: string;
}

export interface ReplyDetails {
    name: string;
    id: string;
    time: string;
}

export type NotificationRange = "fallback" | "subscribers" | "none";

export interface Notifications {
    emailOnPublish: NotificationRange;
    mobileOnPublish: NotificationRange;
    smsOnPublish: NotificationRange;
    teamsOnPublish: NotificationRange;
    reminders: Reminder[];
}

export interface Reminder {
    channel: ReminderChannel;
    delay: number;
    range: NotificationRange;
}

export type ReminderChannel = "inherit" | "email" | "mobile" | "sms" | "teams" | "none";

export interface TranslatedContent {
    [languageCode: string]: TranslatablePostContent
}

export interface TranslatablePostContent extends TranslatableContent {
    complianceText?: string;
    excerpt?: string;
}

export interface PostListItem {
    id: string;
    draftId: string;
    imageUrl?: string;
    translatedContent: TranslatedContent;
    tags: { id: string, name: string }[];
    author: string;
    authorFullName: string;
    commentingEnabled: boolean;
    updatedTime: Epoch;
    publishedTime: Epoch;
    expiryTime: Epoch;
    breakingTime: Epoch;
    featuredTime: Epoch;
    lastModifiedBy: string;
    lastSubmittedTime: Epoch;
    source: string;
    status: POST_STATUS_TYPES;
    title: string;
    postType: string;
    video: { id: string, url: string };
    attachedContent?: AttachedFile[];
    isSubmission: boolean;
    lastPublishedPostId?: string;
    isPreviouslyPublished: boolean;
}

export interface PostDeleteModel {
    draftId: string
    status: string
}

export interface Revision {
    id: string,
    parentId: string,
    updatedTime: Epoch,
    publishedTime: Epoch,
    author: { id: string, name: string }
}

export interface Epoch {
    date: Date,
    epoch: Number
}

export interface TagGroups {
    id: string,
    name: string,
    tags: Tag[]
}

export interface Tag {
    id: string,
    name: string
}

export class Document {
    id: string;
    blobId: string;
    name: string;
    createdTime: { date: string };
    editedTime: { date: string };
    url: string;
    videoId?: string;
}
export interface DraftPostPublishingValidationErrors {
    id: string | null,
    title: string | null,
    errors: { [key: string]: string[] }
}

export interface ValidationError {
    [key: string]: string,
}

export interface ValidationResponse {
    Message: string;
    ModelState: {
        [prop: string]: string[]
    }
}


export interface PostFeedFilters {
    ignoreSubscriptions: boolean;
    lockSubscriptions: boolean;
    lcidToSearch: string;
    newerThan: string;
    olderThan: string;
    postTypes: string[];
    postStates: string[];
    textToSearch: string;
    tags: string[];
    lockedTags: string[];
}


export class PostFilterValues {
    public ignoreSubscriptions: boolean;
    public lockSubscriptions: boolean;
    public lcidToSearch: string;
    public newerThan: string;
    public olderThan: string;
    public postTypes: string[];
    public postStates: PostState[];
    public textToSearch: string;
    public tags: string[];
    public lockedTags: string[];
    public sortType: SortStyle;
    public createdByMeOnly: boolean;
    public includeImageUrl: boolean;
    public includeExpired: boolean;
}

export const defaultDraftPostFilterValues: PostFilterValues = {
    ignoreSubscriptions: false,
    lockSubscriptions: false,
    lcidToSearch: "en-us",
    newerThan: "",
    olderThan: "",
    postTypes: [],
    postStates: [PostState.Draft],
    textToSearch: "",
    tags: [],
    lockedTags: [],
    sortType: SortStyle.modifiedDesc,
    createdByMeOnly: false,
    includeImageUrl: false,
    includeExpired: true,
}

export const defaultSubmissionPostFilterValues: PostFilterValues = {
    ignoreSubscriptions: false,
    lockSubscriptions: false,
    lcidToSearch: "en-us",
    newerThan: "",
    olderThan: "",
    postTypes: [],
    postStates: [PostState.Submission],
    textToSearch: "",
    tags: [],
    lockedTags: [],
    sortType: SortStyle.modifiedDesc,
    createdByMeOnly: false,
    includeImageUrl: false,
    includeExpired: true,
}

export const defaultPublishedPostFilterValues: PostFilterValues = {
    ignoreSubscriptions: false,
    lockSubscriptions: false,
    lcidToSearch: "en-us",
    newerThan: "",
    olderThan: "",
    postTypes: [],
    postStates: [],
    textToSearch: "",
    tags: [],
    lockedTags: [],
    sortType: SortStyle.publishDesc,
    createdByMeOnly: false,
    includeImageUrl: false,
    includeExpired: true,
}

export interface ActivityInsightsDetails {
    reactionCounts: { reactionId: string, reactionName: string, count: number }[]
    totalUniqueReactors: number
    totalUniqueCommentors: number
    totalReactions: number
    totalUniqueInteractions: number
    topComments: { commentBody: string, firstName: string, lastName: string, createdTime: Date, upvoteCount: number, avatarColor: string }[]
    totalFlaggedItems: number
    flaggedItems: FlaggedComment[]
}

export interface PostInsightBreakdowns {
    languageBreakdown: LanguageBreakdown[]
    channelBreakdown: ChannelBreakdown[]
    reportIsReady: boolean
}

export interface PostAverages {
    tenantId?: string
    weekOf?: Date,
    runTimestamp?: Date,
    averageReadingTime: string
    averageWordCount: number
    averageGradeLevel: number
    averageReadabilityScore: number
    averageTone: number
    averagePersonalism: number
    averageSentiment: number
    averageGenderScore: number
    averageWordCountLabel: string;
    averageReadabilityLabel: string;
    averageToneLabel: string;
    averageSentimentLabel: string;
    averagePersonalismLabel: string;
}

export interface LanguageBreakdown {
    lcid: string,
    language: string,
    viewsOnLCID: number
}

export interface ChannelBreakdown {
    platformName: string,
    viewsOnPlatform: number
}

export interface DetailedPostInsights {
    allTimeSkims: number
    allTimeReads: number
    allTimeCompleteReads: number
    allTimeOutlierReads: number
    first7DaysSkims: number
    first7DaysReads: number
    first7DaysCompleteReads: number
    first7DaysOutlierReads: number
    first30DaysSkims: number
    first30DaysReads: number
    first30DaysCompleteReads: number
    first30DaysOutlierReads: number
    allTimeUpvotes: number
    first7DaysUpvotes: number
    first30DaysUpvotes: number
    averageTimeSpentReadingUI: string
    totalSubscriberCountAtPublishTime: number | null
    reachableUsersAtPublishTime: number | null
    totalEngagement: number
    allTimeUniqueOpens: number
    allTimeTotalViews: number
    allTimeComments: number
    allTimeReactions: number
    first7DaysComments: number
    first7DaysReactions: number
    first30DaysComments: number
    first30DaysReactions: number
    totalCompliedUsers: number
    usersNotViewedNotComplied: number | null
    usersViewedNotComplied: number | null
    currentUserHasComplied: boolean
    firstPublishedDate: Date
    reportIsReady: boolean
}

export interface PostAnalysisScores {
    wordCount: number,
    longestSentenceWords: string,
    ieltsScore: string, //max value is 8
    ieltsScoreText: string,
    readabilityScore: string,
    readabilityText: string,
    fogScore: string,
    fogScoreText: string,
    readingTime: string,
    tone: string,
    toneNumber: number, //max value is 100
    uiSentimentScore: number //ranges -1 to 1
    personalism: number //max value is 100
    personal: string
    rawSentiment: string
    sentiment: string
    genderNumber: number
    lixScore: number;
    longestWord: string;
    lettersPerWord: string;
    sentencesPerParagraph: string;
    sentimentScore: number;
    speakingTime: string;
    syllablesPerWord: string;
    wordsPerParagraph: string;
    wordsPerSentence: string;
    sentenceCount: number;
    readabilityIndicator?: IIndicatorDetail;
    toneIndicator?: IIndicatorDetail;
    sentimentIndicator?: IIndicatorDetail;
    personalismIndicator?: IIndicatorDetail;
}

export const defaultPostAnalysisScores: PostAnalysisScores = {
    wordCount: 0,
    longestSentenceWords: "",
    ieltsScore: "",
    ieltsScoreText: "",
    readabilityScore: "",
    readabilityText: "",
    fogScore: "",
    fogScoreText: "",
    readingTime: "",
    tone: "",
    toneNumber: 0,
    uiSentimentScore: 0,
    personalism: 0,
    personal: "",
    rawSentiment: "",
    sentiment: "",
    genderNumber: 0,
    lixScore: 0,
    longestWord: "",
    lettersPerWord: "",
    sentencesPerParagraph: "",
    sentimentScore: 0,
    speakingTime: "",
    syllablesPerWord: "",
    wordsPerParagraph: "",
    wordsPerSentence: "",
    sentenceCount: 0,
}

export interface TipsState {
    loading: boolean,
    dismissed: boolean,
    idealWordCount: number,
}

export const EmptyDefaultPost: Post = {
    id: '',
    tags: [],
    author: '',
    image: { id: '', url: '', transforms: { points: [], zoom: 0 } },
    video: null,
    commentingEnabled: true,
    commentingType: "standard",
    createdTime: null,
    publishTime: null,
    expiryTime: null,
    breakingTime: null,
    featuredTime: null,
    notifications: {
        emailOnPublish: "none",
        mobileOnPublish: "subscribers",
        smsOnPublish: "none",
        teamsOnPublish: "subscribers",
        reminders: [
            { channel: "none", delay: 4, range: "fallback" }
        ]
    },
    revisions: [],
    reactingEnabled: true,
    translatedContent: { "en-us": { title: "", body: "", description: "" } },
    postType: null,
    attachedContent: [],
    fileAttachments: [],
    isSubmission: false,
}

const defaultListingPage: PostListingPage = {
    id: PostListingPageId.EMPTY,
    currentPage: 0,
    posts: [],
    totalPages: 0,
    totalPosts: 0,
    isFetching: false
};

export const DefaultPostsState: PostsState = {
    draftPostList: { ...defaultListingPage, id: PostListingPageId.DRAFTS },
    expiredPostList: { ...defaultListingPage, id: PostListingPageId.EXPIRED },
    publishedPostList: { ...defaultListingPage, id: PostListingPageId.PUBLISHED },
    scheduledPostList: { ...defaultListingPage, id: PostListingPageId.SCHEDULED },
    submissionPostList: { ...defaultListingPage, id: PostListingPageId.SUBMISSIONS },
    allPostList: { ...defaultListingPage, id: PostListingPageId.ALL },
    editor: {
        post: {} as Post,
        supportItem: {} as SupportItem,
        fetching: false,
        saving: false,
        deleting: false,
        image: { id: null, url: null, transforms: null },
        changedSinceSaved: false,
        isValid: false,
        validationChecks: {
            checks: []
        }
    },
    creatingNewPost: false,
    imageLibrary: {
        fetching: false,
        deleting: false,
        uploading: false,
        images: [],
        mediaFiles: [],
        shouldFetch: true,
        lastFetched: null,
        continuationToken: null,
        imagesGalleryMap: {},
        chonkyFileData: [],
        filteredChonkyFileData: [],
        totalCount: 0,
        totalPages: 0,
        folderChain: [],
        sortGroup: SortGroupName.Newest,
        toggleShowFoldersFirst: true,
    } as ImageLibraryState,
    documentDirectory: {
        fetching: false,
        deleting: false,
        uploading: false,
        files: [],
        shouldFetch: true,
        lastFetched: null,
        continuationToken: null
    } as DocumentDirectoryState,
    errorMessage: "",
    isInitialLoad: true,
    postsFeed: {
        postsFeed: [],
        canLoadMore: false,
        errorMessage: "",
        fetching: false,
        filters: {
            postTypes: [],
            postStates: [],
            tags: [],
            textToSearch: ""
        },
        filtersApplied: false,
        shouldFetch: true,
        showFilters: false
    },
    postView: {
        errorMessage: "",
        savingComment: false,
        shouldFetch: false,
        savingReaction: false
    },
    publishing: false,
    submitting: false,
    publishValidationErrors: {
        errors: {},
        title: null,
        id: null
    },
    publishConfirmation: {
        shouldShowDialog: false,
        expectedPublishTime: '',
        containsVideoContent: false
    },
    unpublishing: false,
    tips: {
        loading: false,
        dismissed: false,
        idealWordCount: 0
    },
    activeSupportTab: SupportTab.FAQ,
    shouldFetch: false
}

export interface TagDictionary {
    [tagId: string]: Tag
}

export enum ImageScale {
    Raw,
    Preview,
    Email,
    Mobile,
    Attached,
    Gallery,
    Unprocessed,
    Modal
}

export type PostsSortField = "Title" | "Type" | "Last Modified" | "Published" | "Expiry Date" | "Author" | "";

export const POST_TYPES = {
    COMPLIANCE: "compliance",
    STANDARD: "standard",
    MANDATORY: "mandatory",
    PUBLIC: "public"
}

export enum POST_STATUS_TYPES {
    DRAFT = "draft",
    PUBLISHED = "published",
    SCHEDULED = "scheduled",
    PUBLISHING = "publishing",
}