interface FileProps {
    name: string
    type?: string 
}

export class FileDownloader {
    static readonly DEFAULT_FILE_TYPE = "text/csv;charset=utf-8;";
    static readonly COMPRESSED_FILE_TYPE = "application/x-zip-compressed";
    static readonly POWERPOINT_PRESENTATION_FILE_TYPE = "application/vnd.openxmlformats-officedocument.presentationml.presentation";

    private file: FileProps;

    private readonly UTF8_BOM = "\uFEFF";

    constructor(file: FileProps) {
        this.file = {
            name: this.replaceInvalidCharacters(file.name),
            type: file.type
        }
    }

    private replaceInvalidCharacters = (filename : string) => {
        // more problematic characters can be added here in the future
        return filename.replace(/:/g, '\uA789')
    }

    public downloadBlob = (data: string) => {
        const encodedData = this.isCompressed() ? data : this.UTF8_BOM + data;
        const blob = new Blob([encodedData], { type: this.file.type ?? FileDownloader.DEFAULT_FILE_TYPE });

        this.processBlobDownload(blob);
    }

    public downloadDoc = (data: string) => {
        const blob = new Blob([data]);
        this.processBlobDownload(blob);
    }

    public downloadProvided = (url: string) => {
        if (!!url) {
            const link = document.createElement("a");
            this.autoDownload(url, link);
        }
    }

    public downloadSampleCSV = (lines: string [][]) => {
        const csvContent = lines.join("\n");
        const encoded = this.file.type + encodeURI(csvContent);
        this.downloadProvided(encoded)
    }

    private isCompressed() {
        return this.file.type === FileDownloader.COMPRESSED_FILE_TYPE;
    }

    private processBlobDownload(blob: Blob) {
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, this.file.name);
        }
        else {
            const link = document.createElement("a");
            // feature detection - browsers that support HTML5 download attribute
            if (link.download !== undefined) this.autoDownloadObject(blob, link);
        }
    }

    private autoDownloadObject = (blob: Blob, link: HTMLAnchorElement) => {
        const url = URL.createObjectURL(blob);
        this.autoDownload(url, link);
        URL.revokeObjectURL(url);
    }

    private autoDownload(url: string, link: HTMLAnchorElement) {
        link.setAttribute("href", url);
        link.setAttribute("download", this.file.name);
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}