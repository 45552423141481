import { useRef, useState } from "react";

export type FakeProgression = {
    startFakeProgress: () => void;
    clearFakeProgress: () => void;
    numCompleted: number;
    setFakeProgressIntervalSpeed: (speed: number) => void;
    // used for allowing the fake progression number to reach the totalNumber; completing the fake progression
    allowFinalIncrement: React.MutableRefObject<boolean>;
}

export const MIN_INTERVAL_SPEED_MS = 50;
export const MAX_INTERVAL_SPEED_MS = 3000;

export const useFakeProgression = (totalNumber: number): FakeProgression => {
    const [numCompleted, setNumCompleted] = useState(0);
    const allowFinalIncrement = useRef(false);
    const [fakeProgressIntervalSpeed, setFakeProgressIntervalSpeed] = useState(MAX_INTERVAL_SPEED_MS);
    const fakeDownloadIntervalRef = useRef<NodeJS.Timeout | undefined>();

    // gets a random, arbitrary number proportional to the number in to generate a fake interval time
    const getRandomArbitraryProportional = (num: number): number => {
        const factor = 0.1;

        const min = factor * num;
        const max = (1 + factor) * num;

        return Math.random() * (max - min) + min;
    }

    // set timeouts to increment the number of post exported to mimic actual progress while we wait
    const startFakeProgress = () => {
        setNumCompleted(preVal => Math.min(preVal + 1, allowFinalIncrement.current ? totalNumber : totalNumber - 1));
        fakeDownloadIntervalRef.current = setTimeout(startFakeProgress, getRandomArbitraryProportional(fakeProgressIntervalSpeed));
    };
    
    const clearFakeProgress = () => {
        if (fakeDownloadIntervalRef.current) {
            clearInterval(fakeDownloadIntervalRef.current);
            fakeDownloadIntervalRef.current = undefined;
            setFakeProgressIntervalSpeed(MAX_INTERVAL_SPEED_MS);
            allowFinalIncrement.current = false;
            setNumCompleted(0);
        }
    };

    return {
        startFakeProgress,
        clearFakeProgress,
        numCompleted,
        setFakeProgressIntervalSpeed,
        allowFinalIncrement
    };
}