import { Button, Typography } from '@mui/material';
import LoadingCircle from 'modules/common/components/loadingCircle';
import * as React from 'react';
import BaseComponentOverlay from './baseComponentOverlay';

export enum ActionOverlayType {
    Upload = "Upload",
    Export = "Export"
}

export enum ActionOverlayContentType {
    Image = "Image",
    Post = "Post"
}

interface IActionOverlayProps {
    show: boolean;
    totalNumToUpload: number;
    action: ActionOverlayType;
    contentType: ActionOverlayContentType;
    numCurrentlyUploaded?: number;
    allowCancel?: boolean;
    onUploadCancel?: () => void;
}

const ActionOverlay: React.FC<IActionOverlayProps> = (props) => {
    const [aborting, setAborting] = React.useState(false);

    const onClick = () => {
        setAborting(true);

        if (props.allowCancel && props.onUploadCancel) props.onUploadCancel();
    }

    const lowerCaseAction = props.action.toLowerCase();

    const overlayMessage = () => {
        let message = `${props.action}ing `;

        if (props.numCurrentlyUploaded !== undefined)
        {
            message += `${props.numCurrentlyUploaded} of `;
        }

        message += `${props.totalNumToUpload} ${props.contentType.toLowerCase()}${props.totalNumToUpload > 1 ? "s" : ""}...`;

        return message;
    }

    return (
        <BaseComponentOverlay 
            show={props.show}
            middleComponent={
                <>
                    <LoadingCircle size={80} />
                    <Typography color={'#888'} fontWeight={600} fontSize={20}>
                        { aborting ? 'Aborting...': overlayMessage() }
                    </Typography>
                    {
                        !aborting && 
                        <Typography color={'#888'} fontStyle={'italic'}>
                            {`Stay on this page until ${lowerCaseAction} is completed`}
                        </Typography>
                    }
                    {
                        props.allowCancel && props.onUploadCancel && !aborting && 
                        <Button 
                            variant="text"
                            onClick={onClick}
                            disableFocusRipple
                            href=""
                            type="link"
                            color="primary"
                            autoCapitalize="false"
                            style={{
                                fontWeight: "normal",
                                textTransform: "none",
                                textDecoration: "underline",
                                marginTop: "15px",
                            }}
                        >
                            {`Cancel ${lowerCaseAction}`}
                        </Button>
                    }
                </>
            }
            fullScreen
        />
    )
};

export default ActionOverlay;