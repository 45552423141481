import LoadingOverlay from 'modules/common/components/loadingOverlay';
import React from 'react';
import DragDropOverlay from './dragDropOverlay';
import UploadingOverlay, { ActionOverlayContentType, ActionOverlayType } from '../../common/components/overlays/actionOverlay';
import { ConnectedProps, connect } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';

interface IGalleryVFSOverlayProps {
    showLoadingOverlay: boolean;
    showDragDropOverlay: boolean;
    maxNumUploads: number;
    maxUploadMbSize: number;
    onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
    onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
    fullScreenDragNDrop?: boolean;
    allowUploadCancel?: boolean;
    onUploadCancel?: () => void;
    totalToUpload: number;
    totalUploaded: number;
}

const GalleryVFSOverlay: React.FC<PropsWithRedux> = (props) => {
    return <>
        <LoadingOverlay
            show={props.showLoadingOverlay}
            absolute
        />
        <DragDropOverlay
            show={props.showDragDropOverlay}
            maxNumUploads={props.maxNumUploads}
            maxUploadMbSize={props.maxUploadMbSize}
            onDrop={props.onDrop}
            onDragEnd={props.onDragEnd}
            fullScreen={props.fullScreenDragNDrop}
        />
        <UploadingOverlay
            show={props.imageLibrary.uploading} 
            totalNumToUpload={props.totalToUpload} 
            numCurrentlyUploaded={props.totalUploaded}
            allowCancel={props.allowUploadCancel}
            onUploadCancel={props.onUploadCancel}
            action={ActionOverlayType.Upload}
            contentType={ActionOverlayContentType.Image}
        />
    </>
}

const connector = connect((state: GlobalApplicationState, ownProps: IGalleryVFSOverlayProps)=>({ ...ownProps, imageLibrary: state.posts.imageLibrary, }), {});

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GalleryVFSOverlay);