import { AxiosPromise } from "axios";
import { SparrowApi } from "./network/adapters/SparrowApi";
import { ApiRequest } from "./network/ApiRequest";
import { IDigitalScreensTemplate } from "modules/digitalScreens/models";

export class DigitalScreensApi {
    constructor(private _sparrowApi: SparrowApi) {}

    getDigitalScreensTemplates(): AxiosPromise<IDigitalScreensTemplate[]> {
        return new ApiRequest<IDigitalScreensTemplate[]>(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/digitalScreens/templates`)
        ).catch((err) => {
            throw err;
        });
    }

    exportPostsToDigitalScreens(postIds: string[], lcid: string, templateFileName: string, onDownloadProgress?: (progressEvent: any) => void): AxiosPromise {
        const body = {
            lcid,
            templateFileName,
            postIds
        }

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(
                `/{tenant}/adminapi/v1/digitalScreens`, 
                body, 
                { responseType: 'blob', onDownloadProgress: onDownloadProgress }
            )
        ).catch((err) => {
            throw err;
        });
    }
}