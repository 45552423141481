import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { POST_STATUS_TYPES, POST_TYPES, PostListItem } from "modules/posts/models";
import { push } from "react-router-redux";

const MoreOptions: React.FunctionComponent<PropsWithRedux> = ({
    isContributor = false,
    ...props
}) => {
    let post = props.post;
    const showBoostOption = !isContributor && post.postType !== POST_TYPES.COMPLIANCE;
    const postIsScheduledOrPublished = post.status === POST_STATUS_TYPES.PUBLISHED || post.status === POST_STATUS_TYPES.SCHEDULED;
    const showExportSlideOption = props.digitalScreensEnabled && postIsScheduledOrPublished;

    return (
        <MoreOptionsIconButton>
            <List disablePadding>
                <MoreOptionsItem
                    text="Edit"
                    onClick={props.onEditPost}
                />
                <MoreOptionsItem
                    text="Preview"
                    onClick={() => {
                        props.onPreview(post);
                    }}
                />
                <Divider light />
                {
                    !isContributor &&
                    <MoreOptionsItem
                        text="Insights"
                        disabled={post.status === POST_STATUS_TYPES.DRAFT || post.status === POST_STATUS_TYPES.SCHEDULED}
                        onClick={() => {
                            props.onInsight(post);
                        }}
                    />
                }
                <MoreOptionsItem
                    text="Duplicate"
                    onClick={() => {
                        props.onClone(post);
                    }}
                />
                {
                    (showBoostOption || showExportSlideOption) &&
                    <>
                        <Divider light />
                        {
                            showBoostOption &&
                            <MoreOptionsItem
                                text="Boost"
                                disabled={postIsScheduledOrPublished}
                                onClick={() => {
                                    props.onBoost(post);
                                }}
                            />
                        }
                        {
                            showExportSlideOption &&
                            <MoreOptionsItem
                                text="Export as Slide"
                                onClick={() => {
                                    props.onExport(post);
                                }}
                            />
                        }
                    </>
                }
                <Divider light />
                {
                    !isContributor && post.status !== POST_STATUS_TYPES.DRAFT &&
                    <MoreOptionsItem
                        text={"Unpublish"}
                        onClick={() => {
                            props.onUnpublish(post);
                        }}
                    />
                }
                {
                    !isContributor && post.status === POST_STATUS_TYPES.DRAFT &&
                    <MoreOptionsItem
                        text={"Publish"}
                        onClick={() => {
                            props.onPublish(post);
                        }}
                    />
                }
                <MoreOptionsItem
                    text="Delete"
                    textStyle={{ color: "#B72026" }}
                    onClick={() => {
                        props.onDelete(post);
                    }}
                    disabled={isContributor && post.status !== POST_STATUS_TYPES.DRAFT}
                />
            </List>
        </MoreOptionsIconButton>
    );
}

interface ComponentProps {
    post: PostListItem;
    onPreview: (postToPreview?: PostListItem) => void;
    onPublish: (postToPublish: PostListItem) => void;
    onClone: (postToClone?: PostListItem) => void;
    onBoost: (postToBoost?: PostListItem) => void;
    onUnpublish: (postToUnpublish?: PostListItem) => void;
    onDelete: (postToDelete?: PostListItem) => void;
    isContributor?: boolean;
    onEditPost: () => void;
    onInsight: (postToPreview?: PostListItem) => void;
    onExport: (postToExport?: PostListItem) => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenant: state.tenant.id,
        digitalScreensEnabled: state.settings.tenantSettings.showFeatures.digitalScreensEnabled
    }),
    {
        redirectTo: push,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreOptions);
