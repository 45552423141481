import React from "react";
import { GlobalApplicationState } from "globalApplicationState";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { IBasePostInfo } from "../models";
import { getDigitalScreensButtonText } from "../digitalScreensHelper";

export const MAX_SELECTED_AMOUNT = 10;

interface IExportDigitalScreensBtnProps {
    onClick: () => void;
    selectedPosts: IBasePostInfo[];
}

const ExportDigitalScreensBtn: React.FC<IExportDigitalScreensBtnProps> = ({ onClick, selectedPosts }) => {
    const digitalScreensEnabled = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings.showFeatures.digitalScreensEnabled);

    const isDisabled = !selectedPosts || !digitalScreensEnabled || selectedPosts.length > MAX_SELECTED_AMOUNT;
    const buttonText = getDigitalScreensButtonText(selectedPosts, MAX_SELECTED_AMOUNT);

    return digitalScreensEnabled
        ? <Button
            disabled={isDisabled}
            startIcon={<SystemUpdateAltIcon/>}
            onClick={onClick}
            aria-label={buttonText.toLowerCase()} 
            color="primary"
        >
            {buttonText}
        </Button>
        : <></>
};

export default ExportDigitalScreensBtn;
