import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { IBasePostInfo, IDigitalScreensTemplate } from "../models";
import { getDigitalScreensTemplates } from "../reducer";
import { digitalScreensApi } from "api/instances";
import ActionOverlay, { ActionOverlayContentType, ActionOverlayType } from "modules/common/components/overlays/actionOverlay";
import { FileDownloader } from "utils/fileDownloader";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import ExportDigitalScreensDialog from "./exportDigitalScreensDialog";
import { getDigitalScreensTitle } from "../digitalScreensHelper";
import { MIN_INTERVAL_SPEED_MS, useFakeProgression } from "modules/common/hooks/useFakeProgression";
import { GlobalApplicationState } from "globalApplicationState";

interface IExportDigitalScreensModalProps {
    selectedPosts: IBasePostInfo[];
    showExportDialog: boolean;
    onCloseExportDialog: () => void;
}

export const ExportDigitalScreensModal: React.FC<IExportDigitalScreensModalProps> = ({ 
    selectedPosts = [],
    showExportDialog,
    onCloseExportDialog
}) => {
    const digitalScreensEnabled = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings.showFeatures.digitalScreensEnabled);
    const defaultTenantLcid = useSelector((state: GlobalApplicationState) => state.settings.tenantSettings.defaultLCID);
    const { templates, isFetchingTemplates } = useSelector((state: GlobalApplicationState) => state.digitalScreens);

    const dispatch = useDispatch();
    const [currLcid, setCurrLcid] = useState(defaultTenantLcid);
    const [currTemplate, setCurrTemplate] = useState<IDigitalScreensTemplate | undefined>();
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isExporting, setIsExporting] = useState(false);

    const { startFakeProgress, clearFakeProgress, numCompleted, setFakeProgressIntervalSpeed, allowFinalIncrement } = useFakeProgression(selectedPosts.length);

    const title = getDigitalScreensTitle(selectedPosts);
    const scheduledPostsNum = selectedPosts.filter(post => post.publishedTime && post.publishedTime.date > new Date()).length;
    const publishedPostsNum = selectedPosts.length - scheduledPostsNum;

    useEffect(() => {
        if ((!templates || templates.length === 0) && !isFetchingTemplates)
            dispatch(getDigitalScreensTemplates());
        
    });

    useEffect(() => {
        if (templates.length && !currTemplate) 
            setCurrTemplate(templates[0]);
        
    }, [templates, currTemplate]);

    const onExportClick = async (): Promise<void> => {
        setIsExporting(true);
        startFakeProgress();

        try {
            const resp = await digitalScreensApi.exportPostsToDigitalScreens(
                selectedPosts.map(post => post.id), 
                currLcid, 
                currTemplate?.name ?? "",
                () => {
                    allowFinalIncrement.current = true;
                    setFakeProgressIntervalSpeed(MIN_INTERVAL_SPEED_MS);
                }
            );

            new FileDownloader({ 
                name: `Export as Slides - ${moment().format("DD-MMM-YY")}.pptx`, 
                type: FileDownloader.POWERPOINT_PRESENTATION_FILE_TYPE
            }).downloadDoc(resp.data);

            setSuccessMsg("Successfully exported your posts. Check your downloads folder.");
        }
        catch {
            setErrorMsg("Something went wrong. Please refresh the page and try again.");
        }
        finally {
            setIsExporting(false);
            onCloseExportDialog();
            clearFakeProgress();
        }
    };

    return digitalScreensEnabled && selectedPosts && currTemplate
        ? <>
            <ExportDigitalScreensDialog
                show={showExportDialog && !isExporting}
                onClose={onCloseExportDialog}
                currLcid={currLcid}
                onChangeLcid={lcid => setCurrLcid(lcid)}
                currTemplate={currTemplate}
                onChangeTemplate={template => setCurrTemplate(template)}
                title={title}
                scheduledPostsNum={scheduledPostsNum}
                publishedPostsNum={publishedPostsNum}
                onExport={onExportClick}
            />
            <ActionOverlay
                show={isExporting}
                numCurrentlyUploaded={numCompleted}
                totalNumToUpload={selectedPosts.length}
                action={ActionOverlayType.Export}
                contentType={ActionOverlayContentType.Post}
            />
            <SuccessSnackbar successMessage={successMsg} clearSuccessMessage={() => setSuccessMsg("")}/>
            <ErrorSnackbar errorMessage={errorMsg} clearErrorMessage={() => setErrorMsg("")}/>
        </> 
        : <></>;
};
