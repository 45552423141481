import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import InfoHover from "modules/common/components/hovers/infoHover";
import Disclaimer from "modules/common/components/disclaimer";
import Loading from "modules/common/components/loading";
import DialogField from "modules/common/components/dialogField";
import TemplateInputPicker from "./templateInputPicker";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import LanguageSelect from "./languageSelect";
import { IDigitalScreensTemplate } from "../models";
import { GlobalApplicationState } from "globalApplicationState";
import { MAX_SELECTED_AMOUNT } from "./exportDigitalScreensBtn";

import "../styles/exportDigitalScreensDialog.sass";

interface IExportDigitalScreensDialogProps {
    show: boolean;
    currLcid: string;
    onChangeLcid: (lcid: string) => void;
    currTemplate: IDigitalScreensTemplate;
    onChangeTemplate: (template: IDigitalScreensTemplate) => void;
    title: string;
    scheduledPostsNum: number;
    publishedPostsNum: number;
    onClose: () => void;
    onExport: () => void;
}

const ExportDigitalScreensDialog: React.FC<IExportDigitalScreensDialogProps> = ({
    show,
    currLcid,
    onChangeLcid,
    currTemplate,
    onChangeTemplate,
    title,
    scheduledPostsNum,
    publishedPostsNum,
    onClose,
    onExport
}) => {
    const { templates, isFetchingTemplates } = useSelector((state: GlobalApplicationState) => state.digitalScreens);
    
    const [showTemplatePickerDialog, setShowTemplatePickerDialog] = useState(false);
    const [currSelectedTemplate, setCurrSelectedTemplate] = useState<IDigitalScreensTemplate>(currTemplate);

    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const preloadedImages = useRef(false);

    useEffect(() => {
        let loadCount = 0;
        // for loading up the template images in the background so they're cached in the browser
        const preloadImage = (url: string) => {
            // attach a counter to the onload/onerror to check when we're done loading all template images
            const incrementLoadedTemplate = () => {
                loadCount++;
                if (loadCount == templates.length) setAllImagesLoaded(true);
            }
            const img = new Image();
            img.src = url;
            img.onload = incrementLoadedTemplate;
            img.onerror = incrementLoadedTemplate;
        };

        if (templates.length) {
            if (!preloadedImages.current) {
                templates.forEach(t => preloadImage(t.src));
                preloadedImages.current = true;
            }
        }
    }, [templates]);

    const isLoading = isFetchingTemplates || !templates.length || !allImagesLoaded;
    
    const getDisclaimerPostsText = () => {
        let text = "You've selected";

        if (scheduledPostsNum > 0 && publishedPostsNum > 0) {
            text += ` ${scheduledPostsNum} scheduled and ${publishedPostsNum} published posts`;
        }
        else if (scheduledPostsNum > 0){
            text += ` ${scheduledPostsNum} scheduled post${scheduledPostsNum > 1 ? "s" : ""}`
        }
        else {
            text += ` ${publishedPostsNum} published post${publishedPostsNum > 1 ? "s" : ""}`;
        }

        return text += ` for export. You can export up to ${MAX_SELECTED_AMOUNT} scheduled and published posts at a time.`;
    }

    const onCloseTemplatePickerModal = () => {
        setShowTemplatePickerDialog(false);
        setCurrSelectedTemplate(currTemplate);
    }

    const onSelectTemplateFromPicker = () => {
        currSelectedTemplate && onChangeTemplate(currSelectedTemplate);
        setShowTemplatePickerDialog(false);
    }

    return <>
        <Dialog open={show} onClose={onClose}>
            <DialogTitle className="digital-screens-dialog-title">
                <div className="digital-screens-dialog-title-container">
                    <span>{title}</span>
                    <InfoHover>
                        Export published posts as PowerPoint slides for presentations, promotional materials, digital screens, and more.
                    </InfoHover>
                </div>
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Disclaimer
                    containerStyle={{ marginBottom: 10 }}
                    icon={<InfoOutlinedIcon />}
                    text={
                        <React.Fragment>
                            <span>
                                {getDisclaimerPostsText()}
                            </span>
                        </React.Fragment>
                    }
                />
                {
                    isLoading
                        ? <Loading/>
                        : <Grid 
                            container
                            alignItems={"center"}
                            spacing={1}
                            className="digital-screens-dialog-content-container"
                        >
                            <DialogField
                                fieldName={
                                    <HoverText label="Language">
                                        Select the post translation you want to export. If translation is not available, default language will be used
                                    </HoverText>
                                }
                                fieldInput={
                                    <LanguageSelect
                                        currLcid={currLcid}
                                        onChangeLcid={onChangeLcid}
                                    />
                                }
                            />
                            <DialogField
                                fieldName="Template"
                                fieldInput={
                                    currTemplate &&
                                    <TemplateInputPicker
                                        currTemplate={currTemplate}
                                        onClickEdit={() => setShowTemplatePickerDialog(true)}
                                    />
                                }
                                fieldLabelGridClassName="template-label-grid-item"
                            />
                        </Grid>
                }
            </DialogContent>
            <DialogActions className="digital-screens-dialog-actions">
                <Button variant="text" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={onExport} disabled={!currTemplate}>Export</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showTemplatePickerDialog} fullScreen maxWidth={false} onClose={onCloseTemplatePickerModal}>
            <DialogTitle className="template-picker-dialog-title">
                <div className="template-picker-title-container">
                    <Typography variant="h4" paddingBottom={0}>Change Template</Typography>
                    <div>
                        <Button variant="text" onClick={onCloseTemplatePickerModal}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={onSelectTemplateFromPicker}>Apply</Button>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={onCloseTemplatePickerModal}
                            aria-label="close"
                            className="template-picker-close-btn"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className="template-picker-dialog-content">
                <Grid container spacing={2} columns={15}>
                    {
                        currSelectedTemplate && templates.map(template => 
                            <Grid item lg={3} md={5} key={template.name}>
                                <div 
                                    className={`template-image-container ${template.name === currSelectedTemplate.name ? "selected" : ""}`}
                                    onClick={_ => setCurrSelectedTemplate(template)}
                                >
                                    <img src={template.src} className="template-image"/>
                                </div>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    </>;
};

export default ExportDigitalScreensDialog;
