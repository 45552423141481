import * as React from 'react';

const paddedImage: React.FunctionComponent<IPaddedImageProps> = (props) => {
    
    return (
        <div className={props.containerClassName}>
            <img src={props.src} style={{height: props.height, width: props.width, objectFit: "contain", backgroundColor: props.backgroundColor}} />
            {props.footer && 
                props.footer
            }
        </div>
    )
}

interface IPaddedImageProps {
    src: string;
    height: string;
    width: string;
    backgroundColor: string;
    footer?: JSX.Element;
    onClick?: (any) => void;
    containerClassName?: string;
}

export default paddedImage;