import { Action, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDigitalScreensTemplate } from "./models";
import { digitalScreensApi } from "api/instances";

export interface IDigitalScreensState {
    templates: IDigitalScreensTemplate[];
    isFetchingTemplates: boolean;
}

export interface ISetDigitalScreensTemplatesAction extends Action {
    payload: IDigitalScreensTemplate[];
}

export const defaultDigitalScreensState: IDigitalScreensState = {
    templates: [],
    isFetchingTemplates: false,
}

export const getDigitalScreensTemplates = createAsyncThunk<void, void, { state: IDigitalScreensState }>(
    "GET_DIGITAL_SCREENS_TEMPLATES",
    async (_, { dispatch }) => {
        try {
            const { data } = await digitalScreensApi.getDigitalScreensTemplates();

            dispatch({ type: digitalScreensSlice.actions.SET_DIGITAL_SCREENS_TEMPLATES, payload: data});
        } catch (err) {
            throw err;
        }
    },
    {
        condition: (_, { getState }) => {
            const { isFetchingTemplates, templates } = getState(); 
            return !isFetchingTemplates && !templates;
        }
    }
);

export const digitalScreensSlice = createSlice({
    name: "digitalScreens",
    initialState: defaultDigitalScreensState,
    reducers: {
        SET_DIGITAL_SCREENS_TEMPLATES: (state: IDigitalScreensState, action: ISetDigitalScreensTemplatesAction) => {
            state.templates = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDigitalScreensTemplates.pending, (state: IDigitalScreensState) => {
                state.isFetchingTemplates = true;
            })
            .addCase(getDigitalScreensTemplates.fulfilled, (state: IDigitalScreensState) => {
                state.isFetchingTemplates = false;
            })
            .addCase(getDigitalScreensTemplates.rejected, (state: IDigitalScreensState) => {
                state.isFetchingTemplates = false;
            })
    }
})