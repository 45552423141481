import React from "react";
import PortraitImage from "../../common/components/paddedImage";
import { Fab } from "@mui/material";
import CreateIcon from '@mui/icons-material/CreateOutlined';
import { IDigitalScreensTemplate } from "../models";
import "../styles/templateInputPicker.sass";

interface ITemplateInputPickerProps {
    currTemplate: IDigitalScreensTemplate;
    onClickEdit: () => void;
}

const TemplateInputPicker: React.FC<ITemplateInputPickerProps> = ({
    currTemplate,
    onClickEdit
}) => {
    return <PortraitImage
        containerClassName="template-picker-image-container"
        src={currTemplate.src}
        height="100%"
        width="100%"
        backgroundColor="transparent"
        footer={                    
            <Fab className="template-input-fab-edit" size="small" title="Edit" onClick={onClickEdit}>
                <CreateIcon />
            </Fab>
        }
    />;
};

export default TemplateInputPicker;
